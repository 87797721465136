import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  FormControl,
  NativeSelect,
  Divider,
  CircularProgress,
  // Customizable Area Start
  Collapse,
  Dialog,
  TextField,
  Popover,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "./UploadMedia.css";
import CollectPayment from "../../../components/src/CollectPayment";
import { Alert, Autocomplete } from "@material-ui/lab";
import { BsCalendar } from "react-icons/bs";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const configJSON = require("./config");

const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
  <div className="custom-input" onClick={onClick} ref={ref}>
    <BsCalendar className="calendar-icon" />
    <input value={value} placeholder="Select date" readOnly style={{
      fontSize: '14px'
    }}/>
  </div>
));

export const CustomInputMain =  React.forwardRef(({ value, onClick }: any, ref: any) => (
  <div data-test-id={"custom-input-main"} className="custom-input-main" onClick={onClick} ref={ref}>
    <span className="date-text">{value || "Select Date"}</span>
  </div>
));

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides:{
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '8vh',
          padding: "0 20px",
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116': {
          width: '80% !important'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117': {
          width: '84%'
        },
        '&.alertCollapse > .MuiCollapse-wrapper-116 > .MuiCollapse-wrapperInner-117 > div': {
          backgroundColor: 'white',
          padding: '8px 16px',

          borderRadius: '8px',
          color: '#0F172A',
          fontSize: '1em',
          boxShadow: '0px 4px 7px grey',
        }
      }
    },
    MuiTypography: {
      'root': {
        color: "#E2E8F0"
      },
    },
    MuiContainer:{
      'root':{
        padding:'0px !important',
        background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
        height: '100vh'
      }
    },
    MuiSelect:{
      'root':{}
    },
    MuiInputBase:{
      'root':{
        borderBottom:'0px !important',
        '&.MuiInput-underline,&.MuiInput-underline:before,&.MuiInput-underline:after':{
          borderBottom:'0px !important',
        
        },
        
      },
     
    },
    MuiSvgIcon:{
      'root':{
        ' &.MuiNativeSelect-icon':{
          right:'12px'
        }
      }
    },
    MuiNativeSelect:{
      'root':{
        border:'1px solid rgba(203, 213, 225, 1)',
        borderRadius:'8px !important',
        padding:'12px',
        color: "#94A3B8",
        backgroundColor:'transparent',
        '&.MuiNativeSelect-select:focus':{
          backgroundColor:'#000',

        }
      },
      
    },
    MuiButton:{
      root:{
        textTransform:'none',
        height:'3.2em',
        '&.paymentBtn':{
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        },
        '&.cancelBtn':{
          color:'black'
        },
        '&.enabledButton':{
          background: 'linear-gradient(135deg, rgba(140,139,139,1) 7%, rgba(24,23,25,1) 100%);',
        },
        '&.disabledButton,&.disabledButton:hover ':{
          background: 'rgba(104, 104, 104, 1)'

        },
        '&$disabled':{
          background: 'rgba(104, 104, 104, 1) !important'

        },
        fontSize:'1em',
        fontWeight:700
      }
    },
    MuiFormControl:{
      'root':{
        width:'100%'
      }
    }
  }
});

const MenuClick = styled(Box)({
  background: "#01010199",
  padding: "4px 8px 4px 12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  height: "48px",
  boxSizing: "border-box",
  color: "#94A3B8",
  "& .MuiSvgIcon-root":{
    fontSize: "16px"
  }
});

const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper":{
    maxHeight: "calc(100% - 220px)",
    width: "100%",
  },
  "& .formControl":{
    padding: "12px 16px",
    background: "#484848",
    "& .MuiTextField-root":{
      borderRadius: "24px"
    },
    "& .MuiOutlinedInput-root":{
      height: "40px",
      borderRadius: "24px",
      "&:hover .MuiOutlinedInput-notchedOutline":{
        borderColor: "#94A3B8"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#94A3B8"
      },
      
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline":{
      borderWidth: "1px"
    },
  },
  "& .regionsList":{
    padding: 0,
    display: "flex",
    flexDirection: "column",
    listStyle: "none",
    marginTop: 0,
    marginBottom: 0,
    background: "#1B1B1BCC",
  },
  "& .regionsListItem":{
    height: "48px",
    padding: "12px 16px",
    background: "#1B1B1BCC",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFF",
    boxSizing: "border-box"
  },
  "& .regionsListItem:hover":{
    background: "#010101CC",
    color: "#FFF",
  }
});

// Customizable Area End

import Uploadmedia3Controller, {
  Props,
} from "./Uploadmedia3Controller";
import { ArrowBack, Error } from "@material-ui/icons";

export default class PaymentScreen extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { openScannedModal } = this.state;
    const startDate = this.state.startDate && typeof this.state.startDate === "string" ? new Date(this.state.startDate) : this.state.startDate;
    const endDate = this.state.endDate && typeof this.state.endDate === "string" ? new Date(this.state.endDate) : this.state.endDate;
    const btnClass = this.handleDisableContinue() ? 'paymentBtn enabledButton' : 'paymentBtn disabledButton';
    const btnName = this.getPathDetails() === "/PaymentPreview" ? "Pay" : "Upload";

    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <Container style={{ background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%) !important', fontFamily: "Inter", }} maxWidth={"sm"}>
          <Grid container spacing={1} style={{ 
            margin: '0px -8px 8px', 
            height: '48px', 
            background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)', 
            position: 'sticky', 
            top: '0',
            zIndex: 10,
          }}>
            <Grid  xs style={{  padding: '4px 8px 10px',flexGrow: 0 }} item>
              <IconButton data-test-id="backArrowBtn" style={{ paddingLeft: '-1px' }}  onClick={()=> this.navigateBackPaymentScreen()}>
                <ArrowBack style={{ height: '24px', width: '24px', color: "#ffffff", }} />
              </IconButton>
            </Grid>
            <Grid item sm xs={8}  style={{ display: 'grid', padding: '4px 8px 10px', alignItems: 'center' }}>
              <Typography style={{textTransform: 'uppercase', fontSize: '0.8em',  fontWeight: 700, textAlign: 'center' }}></Typography>
            </Grid>
            <Grid xs item container style={{ justifyItems: 'center', padding: '4 8 24',  display: 'grid' }}>
            </Grid>
          </Grid>
          <Typography style={{ fontSize: '1.9em', height: '8vh',  fontWeight: 700, paddingLeft: '5vw', borderBottom: "1px solid #64748B" }}>Upload</Typography>
          <div style={styles.container} >
          {this.state.allScreens.length > 0 ?
            <>
            <Typography style={webStyle.headings}>Screen</Typography>

            <FormControl style={{marginBottom:'24px'}}>
              <MenuClick data-test-id="screen-menu" onClick={this.handleMenuOpen} style={{
                pointerEvents: this.getPathDetails() === "/PaymentPreview" ? 'none' : 'unset'
              }}>
                <Typography className="menuSelectTxt" data-test-id="selected-screen-text">{this.getScreenSelected()}</Typography>
                <KeyboardArrowDownIcon />
              </MenuClick>
             
              <StyledPopover
                data-test-id="popover-data"
                elevation={3}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
              >
                <ul className="regionsList">
                  {
                    this.state.allScreens.map((screeen: any) => 
                      (
                        <li data-test-id="screen_item" key={screeen.id} className="regionsListItem" onClick={() => {
                          this.handleMenuClose();
                          this.changeScreen(screeen.id)
                        }}>
                          {screeen.attributes.screen_name}
                        </li>
                      )
                    )
                  }
                </ul>
              </StyledPopover>
            </FormControl>
            <Typography style={webStyle.headings}>Media Format</Typography>
            <Typography style={webStyle.value}>
              {this.getMediaType()}
            </Typography>
            <Divider style={webStyle.divider}/>  
            {this.getPathDetails() === "/PaymentPreview" && 
              <>
                <Typography style={webStyle.headings}>File Name</Typography>
                {this.state.uploadedFiles.map((item: any, i: any) => {
                  return <Typography style={webStyle.value} data-test-id={"uploaded-filename-" + i}> {item.name}</Typography>
                })}
                <Divider style={webStyle.divider}/>  
              </>
            }
            <Typography style={webStyle.headings}>Start Date</Typography>
            <Box className="start-date-upload">
              <DatePicker
                selected={startDate}
                onChange={(e: any) => this.handleDateChange("startDate", e)}
                excludeDates={this.state.screenBookings || []}
                minDate={new Date()}
                dateFormat="yyyy.MM.dd"
                placeholderText="Select start date"
                disabled={this.getPathDetails() === "/PaymentPreview" || !this.state.screenSelected.id}
                id="start-date-upload"
                customInput={<CustomInputMain />}
              />
            </Box>
            <Divider style={webStyle.divider}/>  
            <Typography style={webStyle.headings}>End Date</Typography>
            <Box className="start-date-upload">
              <DatePicker
                selected={endDate}
                onChange={(e: any) => this.handleDateChange("endDate", e)}
                minDate={startDate}
                excludeDates={this.state.screenBookings || []}
                dateFormat="yyyy.MM.dd"
                placeholderText="Select end date"
                disabled={this.getPathDetails() === "/PaymentPreview" || !this.state.screenSelected.id}
                id="end-date-upload"
                customInput={<CustomInputMain />}
              />
            </Box>
            <Divider style={webStyle.divider}/>  
            <Typography style={webStyle.headings}>Amount</Typography>
            <Typography style={webStyle.value} data-test-id="amount-value">${this.state.amount}</Typography>
            <Box
              sx={{ position: 'fixed', 
                boxSizing: 'border-box',
              }} 
              style={webStyle.buttonArea}
            >
              <Button
                type="submit"
                fullWidth
                data-test-id="cancelBtn"
                variant="text"
                className={'cancelBtn'}
                style={{
                  marginBottom: 8,
                  color: "#ffffff",
                  background: "#000"
                }}
                onClick={()=> this.handleCancel()}
              >
               Cancel
              </Button>
              <Button
                type="submit"
                fullWidth
                data-test-id="uploadProceedBtn"
                variant="contained"
                onClick={()=> this.handleContinuePayment()}
                // onClick={this.handlePaymentDrawer}
                className={btnClass}

                style={{
                  color: "black",
                  marginBottom: 8,
                  background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)"
                }}
                disabled={this.handleDisableContinue()}
              >
                Proceed to {btnName}
              </Button>
            </Box>
            </>
            :
            <div style={styles.circularProgressDiv}>
            <CircularProgress style={{color:'grey'}}/>
            </div>
            }
            </div>
        </Container>
        <Dialog
          fullScreen
          open={openScannedModal}
          keepMounted
          hideBackdrop // Disable the backdrop color/image
          disableEnforceFocus // Let the user focus on elements outside the dialog
          disableBackdropClick
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          BackdropProps={{
            invisible: true,
          }}
          style={{
            position: "fixed",
            top: "80px",
          }}
          PaperProps={{
            style: {
              maxHeight: "100%",
              height: "100%",
              borderTopRightRadius: "20px",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              background: "#000"
            },
          }}
        >
          <Box>
            <Box style={{
              padding: "22px 24px 6px 24px",
              borderBottom: "1px solid #E2E8F0",
              position: "sticky",
              top: "0",
              zIndex: 9999,
              background: "#000"
            }}>
              <Typography style={{
                fontSize: "20px",
                color: "#F8FAFC",
                fontWeight: 700,
                lineHeight: "28px",
              }}>
                {this.state?.screenSelected?.attributes?.screen_name}
              </Typography>
              <Typography style={{
                fontSize: "12px",
                color: "#F8FAFC",
                fontWeight: 400,
                lineHeight: "28px",
              }}>
                {this.state?.screenSelected?.attributes?.region}
              </Typography>
            </Box>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              padding: "40px 24px 140px",
            }}>
              <Box>
                <Typography style={webStyle.headings}>Status</Typography>

                <FormControl style={{marginBottom:'24px'}}>
                <Autocomplete
                    style={{
                      border: "1px solid #E2E8F0",
                      color: '#E2E8F0',
                      borderRadius: '4px'
                    }}
                    id="combo-box]"
                    fullWidth
                    options={configJSON.ScreenStatusData as string[]}
                    disabled
                    value={this.capitalizeFirstLetter(this.getFieldValue('status'))}
                    renderInput={(params) => <TextField {...params} inputProps={{
                      ...params.inputProps,
                      style: {
                        color: "#E2E8F0"
                      }
                    }} variant="outlined" />}
                  />
                </FormControl>
              </Box>
              <Box>
                <Typography style={webStyle.headings}>Media Format</Typography>
                <FormControl style={{marginBottom:'24px'}}>
                  <Autocomplete
                    style={{
                      border: "1px solid #E2E8F0",
                      color: '#E2E8F0',
                      borderRadius: '4px'
                    }}
                    id="combo-box]"
                    fullWidth
                    options={configJSON.ScreenFileTypeData as string[]}
                    disabled
                    value={this.capitalizeFirstLetter(this.getFieldValue('media_format'))}
                    renderInput={(params) => <TextField {...params} inputProps={{
                      ...params.inputProps,
                      style: {
                        color: "#E2E8F0"
                      }
                    }} variant="outlined" />}
                  />
                </FormControl>
              </Box>
              <Box sx={{
                display: "flex",
                gridColumnGap: "16px",
              }}>
                <Box className="start-date-upload-scanned">
                  <Typography style={webStyle.headings}>Start Date</Typography>
                  <DatePicker
                    selected={startDate}
                    onChange={(e: any) => this.handleDateChange("startDate", e)}
                    excludeDates={this.state.screenBookings}
                    minDate={new Date()}
                    dateFormat="yyyy.MM.dd"
                    placeholderText="Select start date"
                    disabled={this.isScreenUnAvailable()}
                    id="start-date-upload-scanned"
                    customInput={<CustomInput />}
                  />
                </Box>
                
                <Box className="start-date-upload-scanned">
                  <Typography style={webStyle.headings}>End Date</Typography>
                  <DatePicker
                    selected={endDate}
                    onChange={(e: any) => this.handleDateChange("endDate", e)}
                    minDate={startDate}
                    excludeDates={this.state.screenBookings}
                    dateFormat="yyyy.MM.dd"
                    placeholderText="Select end date"
                    disabled={this.isScreenUnAvailable()}
                    id="end-date-upload-scanned"
                    customInput={<CustomInput />}
                  />
                </Box>
              </Box>
              <Box style={{
                marginTop: '36px'
              }}>
                <Typography style={{
                  color: "#F1F5F9",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: 700,
                  marginBottom: "4px"
                }}>
                  Technical Requirements
                </Typography>
                <Box style={{
                  color: "#F1F5F9",
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400
                }}>
                  {this.state?.screenSelected?.attributes?.media_format === "image" ? 
                  <ul style={{
                    margin: "0px",
                    paddingLeft: "22px" 
                  }}>
                    <li>
                      <strong>Formats Supported:</strong> JPEG, JPEG 2000, GIF, PNG, WEBP, BMP, TIFF
                    </li>
                    <li>
                      <strong>Image Size:</strong> Up to 12 MB
                    </li>
                    <li>
                      <strong>Image Dimensions:</strong> Minimum 50x50 pixels
                    </li>
                    <li>
                      <strong>Recommendations:</strong>
                      <ul style={{
                        margin: "0px",
                        paddingLeft: "15px" 
                      }}>
                        <li>
                          Don't downscale images to less than 300x300 pixels as it might reduce accuracy.
                        </li>
                        <li>
                          Preferred formats for faster processing: WEBP, JPEG, JPEG 2000.
                        </li>
                        <li>
                          For optimized usage, keep images typically less than 1 MB.
                        </li>
                      </ul>
                    </li>
                  </ul> : 
                  <ul style={{
                    margin: "0px",
                    paddingLeft: "22px" 
                  }}>
                    <li><strong>Formats Supported:</strong> MP4, MPG, MKV, AVI, MOV, OGG, M3U8, FLV, WEBM</li>
                    <li><strong>Video Size:</strong> For larger videos, files up to a 100MB limit are supported.</li>
                    <li>
                      <strong>Video Dimensions and Resolution:</strong>
                      <ul style={{
                        margin: '0',
                        paddingLeft: '15px'
                      }}>
                        <li>Minimum dimensions: 50 pixels in width or height.</li>
                        <li>Recommended resolution: 480p quality is usually enough.</li>
                      </ul>
                    </li>
                    <li><strong>Video Length:</strong> Videos should be less than 60 seconds long.</li>
                  </ul>
                  }
                </Box>
              </Box>
              <Box 
                sx={{ position: 'fixed', 
                  boxSizing: 'border-box',
                }} 
                style={webStyle.buttonArea}
              >
                <Button
                  type="submit"
                  fullWidth
                  data-test-id="cancel-btn"
                  variant="text"
                  className={'cancelBtn'}
                  style={{
                    marginBottom: 8,
                    color: "#ffffff",
                    background: "#000"
                  }}
                  onClick={()=> this.handleCancel()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  data-test-id="upload-proceed-btn"
                  variant="contained"
                  onClick={()=> this.handleContinuePayment()}
                  className={btnClass}
                  style={{
                    color: "black",
                    background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)"
                  }}
                  disabled={this.handleDisableContinue()}
                >
                  Proceed to {btnName}
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
        <Collapse className="alertCollapse" data-test-id="alert-notification" in={this.state.showErrMsg} >
          <Alert 
            icon={<Error style={{ color: 'red' }} fontSize="inherit" />} 
            severity={"error"}  
          >
            {this.state.errMessage}
          </Alert>
        </Collapse>
        <CollectPayment 
          id={this.props.id}
          navigation={this.props.navigation}
          isPaymentDrawer={this.state.isPaymentDrawer} 
          handlePaymentDrawer={this.handlePaymentDrawer} 
          handlePostCreation={this.handlePostCreation}
          data={{...this.state}} 
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  
  headings:{
    fontSize:'0.8em',
    fontWeight:700,
    marginBottom:'5px'
  },
  value:{
    margin: '5px 0 20px',
    fontSize:'1em',
    color: "#94A3B8"
  },
  divider:{
    margin:'12px 0px',
    height:'1.3px',
    background: "#64748B",
  },
  buttonArea: {
    bottom: '18px',
    width: '100%',
    maxWidth: 'calc(100% - 40px)',
  }
};
const styles = {
  
  

  container: {
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    padding:'16px 16px ',
    paddingBottom: '140px'
  },
  child: {
    margin: '25px 0px',
  }, 
  circularProgressDiv:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'100%'
  }
};
// Customizable Area End
