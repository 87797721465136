// src/components/Help.tsx

import React from 'react';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemIcon, Typography, styled, AppBar, Toolbar, Box, Divider } from '@material-ui/core';
import AdManagerController, { Props, configJSON } from '../../../blocks/admanager/src/AdManagerController.web';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';

import MergeEngineUtilities from "../../../blocks/utilities/src/MergeEngineUtilities";

import {
	FaArrowLeft,
	FaSearch
} from "react-icons/fa";

const styles = (theme: Theme) =>
	createStyles({
		root: {
			color: '#ffffff',
			height: '100vh',
		},
		title: {
			marginTop: theme.spacing(8),
			paddingLeft: theme.spacing(2),
			paddingBottom: "10px",
			fontWeight: 'bold',
			fontSize: '16px'
		},
		list: {
			padding: theme.spacing(2),
			color: '#CBD5E1'
		},
		listItem: {
			'&:not(:last-child)': {
				marginBottom: theme.spacing(1),
			},
		},
		listItemIcon: {
			color: '#CBD5E1',
		},
		nested: {
			paddingLeft: theme.spacing(4),
		},
		listItemRightIcon: {
			color: '#CBD5E1',
			display: 'flex',
  		justifyContent: 'flex-end',

		}
	});

class Help extends AdManagerController {

	constructor(props: Props) {
		super(props);
	}

	handleBack = () => {
		this.props.navigation?.navigate('AdManager');
	}

	handlePage=(pageName:string)=>{
		MergeEngineUtilities.navigateToScreen(pageName, this.props)
	}

	renderHeader = () => {
		return (
			<>
				<StyledHeader>
					<FaArrowLeft data-test-id="go-back" onClick={this.handleBack} />

					<Typography data-test-id="search_data" className="pageTitle" onClick={() => { }}>
						{configJSON.headerHelpTxt}
					</Typography>
					<FaSearch data-test-id="searchIcon" onClick={() => this.props.navigation.navigate('ManagerSearch')}  />
				</StyledHeader>
			</>
		);
	};

	render() {
		const { classes }: any = this.props;

		return (
			<Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
				<Toolbar>
            		{this.renderHeader()}
				</Toolbar>
				<div className={classes.root}>
					<Typography variant="h6" className={classes.title}>
						POPULAR HELP RESOURCES
					</Typography>
					<CustomDivider />
					<List component="nav" className={classes.list}>
						<ListItem 
							className={classes.listItem} 
							button 
							onClick={() => this.handlePage('CreateScreen')}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<DescriptionOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Add new screen" />
							<ListItemIcon className={classes.listItemRightIcon}>
								<NavigateNextOutlinedIcon />
							</ListItemIcon>
						</ListItem>
						<ListItem 
							button 
							className={classes.listItem} 
							onClick={() => this.handlePage('LocalListingAds')}
						>
							<ListItemIcon className={classes.listItemIcon}>
								<DescriptionOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Delete the post" />
							<ListItemIcon className={classes.listItemRightIcon}>
								<NavigateNextOutlinedIcon />
							</ListItemIcon>
						</ListItem>
					</List>
				</div>
			</Box>
		);
	}
}

export default withStyles(styles)(Help);

const StyledHeader = styled(AppBar)({
	width: '100%',
	display: "flex",
	flexDirection: 'row',
	justifyContent: "space-between",
	background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
	boxShadow: "none",
	alignItems: "centner",
	padding: "20px 16px 16px 20px",
	color: "#FFF",
	"& .pageTitle": {
		fontSize: "16px",
		fontWeight: 700,
		lineHeight: "22px",
	},
	borderBottom: "1px solid #64748B",
});

const CustomDivider = styled(Divider)({
  backgroundColor: "#334155",
  height: '1px',
})
