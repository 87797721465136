import React from 'react';

import { 
    styled,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    ListItemAvatar, 
    Avatar, 
    Typography, 
		MenuItem,
    Container,
    AppBar,
    Toolbar,
    CircularProgress,
} from '@material-ui/core';
import { FaArrowLeft } from "react-icons/fa";
import { get } from '../commonTable/table';
import { withStyles } from '@material-ui/core/styles';
import { MdMoreHoriz, MdDeleteOutline } from "react-icons/md";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import ConfirmationDrawer from '../ConfirmationDrawer';

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';
export const configJSON = require("../config");
const success_icon = require("../../assets/success-icon.png");

interface Notification {
  id: number;
  type: string;
  message: string;
  timestamp: string;
  status: 'success' | 'rejected' | 'processing';
}

interface S {
  notificationId: null | number;
	headerAnchorEl: null | HTMLElement;
	removeNotificationModal: boolean;
	removeNotificationLoader: boolean;
	readData: boolean;
	readAllNotificationLoader: boolean;
	notificationDetailData: any;
  isLoading: boolean;
	errorMessage: string;
  isDelete: boolean;
  isDeleteLoader: boolean;
  notifications: Notification[];
}
const styles = {
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    'text-align': 'center',
    color: '#FFF',
  },
  
  avatarSuccess: {
    backgroundColor: '#D1FAE5',
    color: '#34D399',
		fontWeight: 700,
  },
  avatarRejected: {
    backgroundColor: '#FEE2E2',
    color: '#DC2626',
		fontWeight: 700,
  },
  avatarProcessing: {
    backgroundColor: '#FEF3C7',
    color: '#D97706',
		fontWeight: 700,
  },
  listItemText: {
    color: '#F8FAFC',
		fontSize: '14px',
		fontWeight: 700,
  },
  listItemTextRead: {
    color: '#CBD5E1',
    fontSize: '14px',
		fontWeight: 700,
  },
  timestamp: {
    color: '#CBD5E1',
		fontSize: '14px',
  },
  container: {
    marginTop: '20px',
    padding: '20px',
    backgroundColor: '#333333',
    borderRadius: '10px',
    color: '#fff',
  },
  titleText: {
    padding: '22px 0px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '1.2rem',
    marginBottom: '10px',
    gap: '12px'
  },
  navigationTitle: {
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    'text-align': 'left',
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFFFFF',
    paragraph: '8px'
  },
  row: {
    display: 'flex',
    color: '#94A3B8',
    padding: '10px',
    background: "#01010180",
		borderBottom: "1px solid #334155",
    'align-items': 'center',
    gap: '20px',
    justifyContent: 'center'
  },
  successStatusWrapper: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    margin: 'auto'
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    bottom: "20px",
    width: '80%',
    borderRadius: "8px",
    backgroundColor: "#fff",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
    },
};

export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

class ManagerScreenDetails extends BlockComponent<Props, S, SS> {
  getNotificationApiID: string = '';
  getNotificationDetailApiID: string = '';
  readAllNotificationApiID: string = '';
  removeNotificationApiID: string = '';

  constructor(props: any) {
    super(props);

    this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
			getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      notificationId: null,
      headerAnchorEl: null,
      removeNotificationModal: false,
      removeNotificationLoader: false,
			readData: false,
      readAllNotificationLoader: false,
      isLoading: false,
      isDelete: false,
      isDeleteLoader: false,
			notificationDetailData: {},
      errorMessage: '',
			notifications: [],
    };
  }

	async componentDidMount() {
    super.componentDidMount();
 
    this.getNotificationList()
	}


  handleRemoveDrawer = () => {
    this.setState({ removeNotificationModal: !this.state.removeNotificationModal });
  };

	apiCall = async ({
		method, endPoint, contentType, body, isFormData
	}: any) => {
		const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isFormData ? body : JSON.stringify(body)
    );

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return requestMessage;
	}

  getNotificationList = async () => {
    this.setState({isLoading: true});
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
      endPoint: configJSON.listNotificationApiEndPoint, 
			contentType: "application/json",
		})
		this.getNotificationApiID = GetRegionAPI.messageId;
	}

	getNotificationDetail = async (notificationId: number) => {
    this.setState({isLoading: true});
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.notificationDetailApiEndPoint}/${notificationId}/notification_details`, 
			contentType: "application/json",
		})
		this.getNotificationDetailApiID = GetRegionAPI.messageId;
	}

  markAsReadNotification = async () => {
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpPutMethod, 
      endPoint: configJSON.markAsReadApiEndPoint, 
			contentType: "application/json",
      body: { "is_read" : true },
      isFormData: false
		})
		this.readAllNotificationApiID = GetRegionAPI.messageId;
	}

  deleteNotification = async () => {
    this.setState({isDeleteLoader: true});
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpDeleteMethod, 
      endPoint: configJSON.deleteNotificationApiEndPoint, 
			contentType: "application/json",
      body: { "id": this.state.notificationId },
      isFormData: false
		})
		this.removeNotificationApiID = GetRegionAPI.messageId;
	}

  getFieldValue = (keyValue: string): string => {
    return get(this.state.notificationDetailData, `attributes.${keyValue}`) || '-'
  }

  updateNotificationList = () => {
    this.setState({ notificationId: null}) 
      this.getNotificationList();
  }

	goBack = () => {
    if (this.state.notificationId) { 
      this.updateNotificationList()
    } else this.props.navigation.goBack()
	}

  goToScreen = () => {
		this.props.navigation.navigate('LocalListingAds')
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
		const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
		if(apiRequestCallId == this.getNotificationApiID){ 
      if(!responseJson.errors && responseJson.data) this.setState({notifications: responseJson.data});
      else this.setState({notifications: []})
      this.setState({isLoading: false});
    } else {
			this.handleSuccessResponse(apiRequestCallId, responseJson)
		}
	}
	handleSuccessResponse = (apiRequestCallId: string, responseJson: any) => {
		if(apiRequestCallId === this.getNotificationDetailApiID){
      if(!responseJson.errors && responseJson.data) this.setState({notificationDetailData: responseJson.data});
			else {
        this.setState({notificationDetailData: []})
        this.updateNotificationList()
      }
      this.setState({isLoading: false});
		} else if(apiRequestCallId === this.readAllNotificationApiID) {
      this.handleHeaderClose();
      this.getNotificationList();
		} else if(apiRequestCallId === this.removeNotificationApiID) {
      this.handleHeaderClose()
      this.updateNotificationList()
      this.setState({errorMessage: "Notification successfully deleted"})
		}
	}

	getMoreMenuData = () => {
		if(this.state.notificationId) {
			return [{
				title: "Delete",
				key: 'delete',
				icon: <MdDeleteOutline />,
			}]
		}
		return [
			{
				title: "Mark as Read",
				key: 'mark_as_read',
				icon: <IoCheckmarkDoneOutline />,
			}]
	}

	handleHeaderMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		this.setState({
      headerAnchorEl: event.currentTarget
    })
	};
	handleHeaderClose = () => {
		this.setState({ headerAnchorEl: null })
	};
  
  handleCancelDelete = () => (
    this.setState({isDelete: !this.state.isDelete})
  )

  handleDelete = () => {
    this.deleteNotification();
    this.handleCancelDelete()
  }

	handleRemoveData = (key?: string) => {
		if(key === 'delete') {
      this.handleCancelDelete();
      this.handleHeaderClose();
		}
		else this.markAsReadNotification()
  }

	getAvatarStyle(status: string) {
    if(status.toLocaleLowerCase().includes('rejected')) return styles.avatarRejected;
    if(status.toLocaleLowerCase().includes('under revision')) return styles.avatarProcessing;
    return styles.avatarSuccess;
      
    
  }

  getMessage = (message: string): string => {
    if(message !== '-') {
      return `${message?.substring(0, 40)}...`;
    }
    return message;
  }

  navigateToDetails = (notificationId: number) => {
    this.setState({notificationId});
    this.getNotificationDetail(notificationId);
  }

  renderLoader = () => (
    <div style={{ ...styles.row, height: (53) * 2, backgroundColor: 'transparent', border: 0 }}>
      <div>
        <CircularProgress style={{ color: "#94A3B8" }} />
      </div>
    </div>
  )

  renderNotificationList = () => {
    if(this.state.isLoading) this.renderLoader();
    return (
      <DetailedScreenBox>
        <List className='list-items' style={{padding: 0}}>
          {this.state.notifications.map((notification: any) => (
            <ListItem key={notification.id} alignItems="flex-start" style={{borderBottom: '1px solid #64748B', padding: '12px 16px'}}>
              <ListItemAvatar>
                <Avatar style={this.getAvatarStyle(notification.attributes.title || 'success')}>
                  {notification.attributes.heading?.[0].toUpperCase() ?? notification.attributes.title[0].toUpperCase()}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography style={notification.attributes.is_read ? styles.listItemTextRead : styles.listItemText}>
                      {notification.attributes.heading?.charAt(0)?.toUpperCase() + notification.attributes.heading?.slice(1) || ''}
                    </Typography>
                    <Typography style={styles.timestamp}>
                      {notification.attributes.created_at}
                    </Typography>
                  </Box>
                }
                secondary={
                    <Typography component="span" style={styles.timestamp}>
                      {notification.attributes.message}
                    </Typography>
                }
              />
            </ListItem>
          ))}
          {!this.state.notifications?.length ? (
            <div style={{height: '100%', display: 'flex', justifyContent: 'center',  color: '#64748B', marginTop: '50px'}} >
              {this.state.isLoading ?  this.renderLoader() : <p> No notification data </p>}
            </div>
          ) : null}
        </List>
      </DetailedScreenBox>
    )
  }
  renderNotificationDetail = () => {
    return (
      <Container maxWidth="sm">
          {!this.state.isLoading ? (
            <>
              <div style={styles.titleText}>
                <Avatar style={this.getAvatarStyle('success')}>{this.state.notificationDetailData?.attributes?.title[0].toUpperCase()}</Avatar>
                <Typography variant="h6" style={styles.navigationTitle}>
                  {this.state.notificationDetailData?.attributes?.title?.charAt(0)?.toUpperCase() + this.state.notificationDetailData?.attributes?.title?.slice(1)}
                </Typography>
              </div>
              <Typography variant="body2" style={styles.bodyText}>
                {this.state.notificationDetailData?.attributes?.message}
              </Typography>
            </>
          ) : this.renderLoader()
          }
      </Container>
    )
  }

  render() {

    return (
      <>
        <Box style={{background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'}}>
          <Toolbar style={{minHeight: '75px'}}>
            <StyledHeader>
              <FaArrowLeft style={{color: '#FFF'}} data-test-id="go-back" onClick={this.goBack} />
              <Typography style={styles.headerTitle}>NOTIFICATION</Typography>
              
              <IconButton
              aria-haspopup="true"
              style={{padding: 0, margin: 0}}
              onClick={(event) => this.handleHeaderMenuClick(event)}
              >
                <MdMoreHoriz style={{color: '#fff', width: '24px'}} data-test-id="searchIcon" />
              </IconButton>

              <StyledMenu
                id="customized-menu"
                anchorEl={this.state.headerAnchorEl}
                keepMounted
                open={Boolean(this.state.headerAnchorEl)}
                onClose={this.handleHeaderClose}
              >
                {this.getMoreMenuData().map((menu: any) => (
                  <StyledMenuItem key={menu.key} onClick={() => this.handleRemoveData(menu.key)}>
                    <ListItemIcon style={{minWidth: "35px"}}>
                      {menu.icon}
                    </ListItemIcon>
                    <StyledListItemText style={{fontSize: '14px'}} primary={menu.title} />
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </StyledHeader>
          </Toolbar>
          <DetailedScreenBox style={this.state.notificationId ? {height: 'calc(100vh - 75px)'} : {}}>
            {
              // this.state.notificationId ? this.renderNotificationDetail() :
              this.renderNotificationList()
            }
          </DetailedScreenBox>


          <ConfirmationDrawer 
            navigation={this.props.navigation} 
            open={this.state.isDelete}
            handleOpen={this.handleCancelDelete}
            handleCancel={this.handleCancelDelete}
            handleSuccess={this.handleDelete}
            title={"Are you sure you want to remove the Ad name ?"}
            activeButtonText={"Confirm"}
            activeButtonLoader={this.state.isDeleteLoader}
          />
          {this.state.errorMessage &&
            <Box style={styles.successStatusWrapper} data-test-id="card_success">
              <Box style={{...styles.successStatus, position: 'fixed'}}>
                <img src={success_icon} /> {this.state?.errorMessage || ''}
              </Box>
            </Box>
          }
        </Box>
      </>
    );
  }
}

export default ManagerScreenDetails;

const DetailedScreenBox = styled(Box)({
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  height: 'calc(100svh - 75px)',
  overflow: 'scroll',

  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const StyledHeader = styled(AppBar)({
  padding: "20px 16px",
  paddingBottom: '30px',
  borderBottom: "1px solid #64748B",
  width: '100%',
  display: "flex",
  flexDirection: 'row',
  justifyContent: "space-between",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  boxShadow: "none",
  alignItems: "centner",
  color: "#FFF",
});

const StyledMenu = withStyles({
  paper: {
    background: "#000",
    color: "#94A3B8",
    borderRadius: "8px"
  },
	list: {
		padding: 0
	},
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)({
  color: '#94A3B8',
  minWidth: '180px',
  '& svg': {
    color: '#94A3B8',
    height: '25px',
    width: '25px',
  },
});


const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '14px',
  },
});
