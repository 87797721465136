import React from 'react'
import {
  Drawer,
  Box,
  Button,
  Typography
} from '@material-ui/core'
import { Close} from '@material-ui/icons';

import { BlockComponent } from "../../framework/src/BlockComponent";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  open: boolean;
  title: string;
  activeButtonText: string;
  activeButtonLoader: boolean;
  handleOpen: () => void;
  handleCancel: () => void;
  handleSuccess: () => void;
}
interface S {}

interface SS {
  id: string;
}


export default class ConfirmationDrawer extends BlockComponent<Props, S, SS> {


  constructor(props: Props) {
    super(props);
  }

  closeAlertPopUp = () => {
    this.props.handleCancel();
  }

  render() {
    return (
			<Drawer 
				className="confirmationAlert"
				anchor={'bottom'}
				open={this.props.open}
				onClose={this.closeAlertPopUp}
				PaperProps={{style:{backgroundColor: 'rgba(0, 0, 0, 1)', width: '100%', borderTopRightRadius: '24px'}}} 
			>
				<Box sx={{ display: 'flex', justifyContent: 'end' }}>
					<Button variant="text" data-test-id="closeBtn" style={{ margin: '20px 3px', padding: '0px' }} onClick={() => this.closeAlertPopUp()}><Close style={{ fontSize: '2em', color: '#FFF' }} /></Button>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>

					<Typography component="div" style={{ fontSize: '18px', fontWeight: 700, margin: '12 0 0', color: '#FFF', lineHeight: '26px' }}>
						{this.props.title}
					</Typography>
					<Box style={{ width: '100%', bottom: '24px', boxSizing: 'border-box'}}>
						<Button
							fullWidth
							key={'cancel'}
							className={'textBtn'}
							variant={'text'}
							data-test-id={`${'cancel'}Btn`}
							onClick={() => this.props.handleCancel()}
							style={{
								padding: '16px 16px',
								borderRadius: '8px',
								display: 'flex',
								fontWeight: 'bold',
								fontSize: '16px',
								textTransform: 'capitalize',
								width: '100%',
								color: '#FFF'
							}}
						>
							Cancel
						</Button>
						<Button
							key={'logout'}
							className={'conatinedBtn'}
							data-test-id={`${'logout'}Btn`}
							variant={'contained'}
              disabled={this.props.activeButtonLoader}
							fullWidth
							style={{
								padding: '16px 16px',
								borderRadius: '8px',
								background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
								display: 'flex',
								fontWeight: 'bold',
								fontSize: '16px',
								width: '100%',
								textTransform: 'none'
							}}
							onClick={() => this.props.handleSuccess()}
						>
							{this.props.activeButtonText}
						</Button>
					</Box>
				</Box>
			</Drawer>
    )
  }

}
