import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
 
  // Customizable Area Start
  Grid,
  FormControlLabel,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ArrowBack, Close } from "@material-ui/icons";
import {GreenCheckbox} from '../../email-account-login/src/EmailAccountLoginBlock.web'


const theme = createTheme({

  typography: {

    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
  palette: {
    primary: {

      contrastText: "#fff",
      main: "#fff",
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        display: "flex",
        width: "17.1%",
        height: "35px",
        marginRight: "24px",
        justifyContent: "center",
        alignItems: "center",

      },
    },
    MuiCheckbox: {
      root: {
        padding: '7px',
      }
    },
    MuiSvgIcon: {
      root: {
        height: "20px",
        width: "20px",
      },
    },
    MuiButton: {
      root: {
        fontSize: '0.8em',
        textTransform: 'none',

        margin: '12 0 0',
        '&.agreeBtn': {
        background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
        borderRadius:'8px !important',
        height:'3.4em',
        fontSize:'1em',  
        fontWeight:700,
        },
        '&.cancelBtn': {
          color:'#FFFFFF',
          background: "#000",
          height: '54px',
          margin: '32 0 0',
          fontWeight: '700',
        },

        '&.Mui-disabled': {
          color: '#64748B',
          backgroundColor: '#F1F5F9 !important',
          fontWeight: '700',


        }
      }
    }
  }
});
// Customizable Area End

import PrivacyPolicyController, {
  Props,
  configJSON,
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.backgroundWrapper}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={1} style={{margin:'0px 0px',borderBottom:'1px solid #E2E8F0',padding:'10px 0px',position: 'fixed',zIndex: 10, top: 0, background:'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',width: '100%'}}>
        <Grid item xs style={{flexGrow:0,padding:0}}>
          { this.state.tokenn && <IconButton onClick={this.navigateBack}>
            <ArrowBack style={{height:'24px',width:'24px',color:'white'}}/>
          </IconButton>}
        </Grid>
          <Grid item sm xs={8} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100vw', padding:this.state.tokenn ? '0' : '12px 0px',marginLeft: this.state.tokenn ? '0' : '45px'}}>
            <Typography style={{textTransform:'uppercase',fontSize:'0.8em',fontWeight:'700',textAlign:'center'}}>Privacy Policy</Typography>
          </Grid>
          <Grid item xs container style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
          {!this.state.tokenn && <Button variant="text" onClick={this.navigateBack} style={{margin:'0px',padding:'0px',color:'white'}}><Close style={{fontSize:'1em',height:'18px',width:'18px'}} /></Button>}
          </Grid>
        </Grid>
        <Container maxWidth={"sm"}>
          <Box className="agreeBtn" sx={webStyle.mainWrapper}>
            <Typography style={{fontWeight:'700',color:'#FFFFFF',margin:'18 0',fontSize:'1.2em',}}>Privacy Policy</Typography>
            {this.state.privacy_policy_data.map(policy => {
                return (
                  <Typography component="div" style={{ color:'#FFFFFF', fontSize: '0.9em', textAlign: 'justify',marginBottom:'8px' }}>
                      <div dangerouslySetInnerHTML={{ __html: policy.description}} />
                  </Typography>
                )
              }
              )
            }
            {!this.state.tokenn &&
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    data-test-id="formControl"
                    control={<GreenCheckbox style={{background: "#fff", fontSize: '1em', margin: '6px 10px 0 0', height: "0", width: "0", borderRadius: "0" }} checked={this.state.isChecked} onChange={this.handleCheck} color="primary" />}
                    label={<span style={{ fontSize: '1em', paddingTop: '0px', fontWeight: 300, height: "0", width : "0"}}>I have read and agree to these Privacy and Policy</span>}
                    style={{ marginRight: '0px', alignItems: 'start',margin: '5px 0 7px 0'}}
                  />
                   {(this.state.isError) && (!this.state.isChecked) && <span  data-test-id="error-message" style={{ fontSize: '0.85em', color: '#F87171', marginBottom: '24px',fontFamily: "Inter"}}>Please accept the Privacy and Policy to continue.</span>}
                </Grid>

              </Grid>}
            
              {!this.state.tokenn && (
                <>
          <Button
            data-test-id="cancelBtn"
            
            className="cancelBtn"
            onClick={this.navigateBack}
            fullWidth
            variant="text"
          >
            Cancel
          </Button>
          <Button
            data-test-id="agreeBtn"
            fullWidth
            variant="text"
            className="agreeBtn"
            onClick={this.handleAgreeClick}
          >
            Agree
          </Button>
          </>
          )}
          </Box>
        </Container>
      </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  backgroundWrapper:{
    background:'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    color:'#FFFFFF',
  },
  mainWrapper: {
    flexDirection: "column",
    display: "flex",
    fontFamily: "Inter",
    alignItems: "start",
    marginTop:'60px',
    padding:'10px'
  },
  inputStyle: {
    
    width: "100%",
    
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100px",
    
  },
  buttonStyle: {
    marginTop: "40px",
    width: "100%",
    
    border: "none",

    backgroundColor: "rgb(98, 0, 238)",
    height: "45px",
  },
  headerText:{
    textTransform:'uppercase'
  }
};
// Customizable Area End
