import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Link,
  Grid,
  FormControlLabel,
  TextField,
  OutlinedInput,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { GreenCheckbox } from '../../email-account-login/src/EmailAccountLoginBlock.web'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      color: 'green'
    },
    h5: {
      fontWeight: 600,
      color: "red",
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    body2: {
      fontSize: '0.7em',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: '#94A3B8',
      }
    },
    MuiSvgIcon: {
      root: {
        width: "20px",
        height: "20px",

      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "1em",

        },
        padding: '10.5px',

        "&:focus": {
          borderRadius: '2px',

        },
      },

    },
    MuiOutlinedInput: {
      root: {
        height: '3.3em',
        borderRadius: '8px !important',
        outline:'1px solid #CBD5E1',
        '&$error': {
          outline: 'none',
          borderColor: '#DC2626',
          '& fieldset': {
            borderColor: '#DC2626',
          },
        },
      },
      input: {
        height: '100%',
        padding: '0px 10.5px',
      }
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "17.1%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiCheckbox: {
      root: {
        padding: '7px',
      }
    },

    MuiButton: {
      root: {
        "&.signUpButton": {
          borderRadius: '8px !important',
          height: '3.4em',
          fontSize: '1em',
          fontWeight: 700,
          background: 'linear-gradient(#FFFFFF 100%,#919191 100%)',
          color: '#1E293B',

        },
        textTransform: 'none',
      }
    }
  },
});
import { CheckCircle } from "@material-ui/icons";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { app_logo, background, viewa, viewb } from "./assets";
// Customizable Area End

import SignuploginController, {
  Props,
  configJSON,
} from "./SignuploginController";
import Loader from "../../../../packages/components/src/Loader";


export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div style={{ background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)', backgroundRepeat: 'no-repeat', position: 'fixed', backgroundSize: '100% 100%', width: '100vw', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          <Container maxWidth={"sm"} style={{ height: '100%' }}>
            <img src={viewa} alt="viewa" style={{ position: 'absolute', top: "0px", right: "-25px" }}></img>
            <img src={viewb} alt="viewb" style={{ position: 'absolute', bottom: 0, left: 0 }}></img>
            <Box sx={webStyle.mainWrapper}>
              <img src={app_logo} alt="app_logo" style={webStyle.logo} />
              {(this.state.isFormSubmit && (this.state.errorMsg || this.state.terms_and_conditions_errMsg)) &&
                <Box sx={webStyle.alertBox}>
                  {this.state.errorMsg ? this.state.errorMsg : this.state.terms_and_conditions_errMsg}
                </Box>
              }
              {this.state.isLoading ? <Loader loading={true} /> : null}
              <form style={webStyle.form}>
                <InputLabel style={{ fontSize: '1em', fontWeight: '700', margin: '8 0', color: '#E2E8F0' }}>
                  Full Name
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="full_name"
                  placeholder="Your full name"
                  name="full_name"
                  autoComplete="full_name"
                  data-test-id="full_name_input"
                  value={this.state.fullName}
                  onChange={(event) => this.setFullName(event.target.value)}
                  style={{ marginBottom: '5px', color: '#E2E8F0' }}
                  error={this.state.isFormSubmit && !this.state.fullName}
                />
                {(this.state.isFormSubmit && !this.state.fullName) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Full Name is required</span>}
                <InputLabel style={{ fontSize: '1em', fontWeight: '700', margin: '24 0 8', color: '#E2E8F0' }}>
                  Email
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  id="email"
                  placeholder="Your email"
                  name="email"
                  // autoComplete="email"
                  data-test-id="emailinput"
                  onBlur={() => this.validateEmail()}
                  value={this.state.email}
                  onChange={(event) => this.setEmailAddress(event.target.value)}
                  style={{ marginBottom: '5px', color: '#E2E8F0' }}
                  error={!this.state.isEmailValid || (this.state.isFormSubmit && !this.state.email)}
                />
                {(!this.state.isEmailValid) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Invalid email</span>}
                {(this.state.isFormSubmit && !this.state.email) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Email is required</span>}
                <InputLabel style={{ fontSize: '1em', fontWeight: '700', margin: '24 0 8', color: '#E2E8F0' }}>
                  Password
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  name="password"
                  value={this.state.password}
                  type={this.state.showPassword ? "password" : 'text'}
                  id="password"
                  placeholder="Your password"
                  style={{ margin: '0px 0px 5px', color: '#E2E8F0' }}
                  data-test-id='passwordInput'
                  onFocus={() => this.validatePassword('focus')}
                  onBlur={() => this.validatePassword('blur')}
                  onChange={(event) => this.setPassword(event.target.value)}
                  error={this.state.isFormSubmit && !this.state.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.setShowPassword}
                        edge="end"
                      >
                        {!this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {(this.state.isFormSubmit && !this.state.password) && <span style={{ fontSize: '0.75em', color: '#DC2626', marginBottom: '24px' }}>Password is required</span>}
                {this.state.showPasswordValidation && <Grid style={{ margin: '12px 0px', color: '#F1F5F9' }} item>
                  <Box sx={{ width: "100%", minHeight: "60px" }}>
                    <Box sx={{ fontSize: '1em', color: '#F1F5F9', fontWeight: '400', margin: '16 0 8' }}>Your password must contain</Box>
                    {this.state.passwordErrors.map((item: any, index: number) => {
                      return (
                        <Box
                          sx={{

                            flexDirection: "row",
                            marginTop: "5px",
                            display: "flex",
                            alignItems: "center",

                            fontFamily: "Inter",
                            flexWrap: "no-wrap",
                          }}
                          key={index}>
                          <Box sx={{ marginRight: "5px" }}>
                            {item.isValid ? <CheckCircle style={{ color: '#34D399', }} fontSize="small" /> : <RemoveCircleIcon fontSize="small" style={{ color: "#DC2626" }} />}
                          </Box>
                          <Box sx={{ fontSize: '0.9em', }}>{item.msg}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>}
                <Grid container>
                  <Grid item xs>
                    <FormControlLabel
                      data-test-id="formControl"
                      control={<GreenCheckbox style={{background: "#fff", fontSize: '1em', margin: '13px', lineHeight: "20px", height: "0", width: "0", borderRadius: "0" }} checked={this.state.isChecked} onChange={(event) => this.setTermsAndCondition(event.target.checked)} value="termsAndCondition" color="primary" />}
                      label={<span style={{ fontSize: '1em', paddingTop: '0px', fontWeight: 400, color: '#CBD5E1', height: "0", width: "0" }}>I agree with
                        <Button data-test-id="linkToNavigatePrivacy" onClick={() => this.navigateToLink('PrivacyPolicy')} variant="text" style={{ fontSize: '0.9em', color: '#CBD5E1', margin: "0 -8px 3px -3px" }}>
                          Privacy Policy</Button> and <Button data-test-id="linkToNavigateTerms" onClick={() => this.navigateToLink('Termsandconditions')} variant="text" style={{ fontSize: '0.9em', padding: 0, color: '#CBD5E1', marginBottom: '3px' }}>
                          Terms and</Button>
                        <Button data-test-id="linkToNavigateTerms2" onClick={() => this.navigateToLink('Termsandconditions')} variant="text" style={{ fontSize: '0.9em', padding: 0, color: '#CBD5E1', margin: "-5px 0 0 5px" }}>
                          Conditions</Button>
                      </span>}

                      style={{ marginRight: '0px', alignItems: 'start' }}
                    />
                  </Grid>
                </Grid>

                <Button
                  data-test-id="submitBtn"
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={this.state.isLoading}
                  style={webStyle.submit}
                  className="signUpButton"
                  onClick={this.state.isLoading != true ? this.submitForm : () => {}}
                >
                  Sign up
                </Button>
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'baseline' }}>
                  <span style={{ fontSize: '1em', color: '#CBD5E1' }}>Already have an account?</span>
                  <Button data-test-id="linkToNavigateLogin" onClick={() => this.navigateToLink('EmailAccountLoginBlock')} variant="text" style={{ color: '#F8FAFC', fontSize: '0.9em', fontWeight: 700, marginLeft: 3 }}>
                    Log in
                  </Button>
                </div>
              </form>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    alignItems: "center",
    height: '90%',
  },
  logo: {
    filter: 'invert(1)',
    marginTop: "75px",
  },
  formWrapper: {
    width: '96%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
  },
  form: {

    width: '100%',

    marginTop: theme.spacing(4)
  },
  submit: {
    margin: "32px 0",
    background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)",
  },
  alertBox: {

    backgroundColor: '#FEE2E2',
    borderRadius: '4px',
    color: 'red',
    fontSize: '0.9em',
    borderLeft: '4px solid red',
    padding: '8px 12px',
    margin: '12px 0',
    width: '90%'
  }
};
// Customizable Area End
