import React from 'react';

import { 
    styled,
    Box,
    List,
    ListItem,
    ListItemText,
    Tooltip,
    IconButton,
    Button
} from '@material-ui/core';
import { FaArrowLeft } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { get } from '../commonTable/table';
import moment from 'moment';


import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';
export const configJSON = require("../config");



const styles = {
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
  buttonArea: {
    bottom: '18px',
    width: '100%',
    maxWidth: 'calc(100% - 40px)',
  },
};


export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

class ManagerAdDetails extends BlockComponent<Props, any, SS> {
  getAdDataApiID: string = '';

  constructor(props: any) {
    super(props);

		this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      modalAdd: false,
			fileDetailData: {},
      viewAd: false,
      errorMessage: '',
      screenUrlCopy:false,
    };
  }

	async componentDidMount() {
    super.componentDidMount();

		this.getRegionList();
	}

	apiCall = async ({
		method, endPoint, contentType, body, isFormData
	}: any) => {
		const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isFormData ? body : JSON.stringify(body)
    );

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return requestMessage;
	}

	getRegionList = async () => {
    const adId = this.props.navigation.getParam('adId');

		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.getAdDetailsApiEndPoint}${adId}`, 
			contentType: "application/json",
		})

		this.getAdDataApiID = GetRegionAPI.messageId;
	}

  getFieldValue = (keyValue: string): string => {
    return get(this.state.fileDetailData, `attributes.${keyValue}`) || '-'
  }

  handleCopy = () => {
    const screenUrl = `${this.getFieldValue("screen_url")}`;
    navigator.clipboard.writeText(screenUrl).then(() => {
      this.setState({ screenUrlCopy: true });
      setTimeout(() => this.setState({ screenUrlCopy: false }), 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };


	goBack = () => {
		this.props.navigation.goBack()
	}

  goToScreen = () => {
		this.props.navigation.navigate('LocalListingAds')
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
		const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

		if(apiRequestCallId == this.getAdDataApiID){ 
      if(!responseJson.errors && responseJson.file) this.setState({fileDetailData: responseJson.file.data});
      else {this.setState({fileDetailData: {}})}
    }
	}

  getScreenUrlAddress = () => {
    if(this.getFieldValue("screen_url") !== '-') {
      return `${this.getFieldValue("screen_url")?.substring(0, 40)}...`;
    }
    return this.getFieldValue("screen_url");
  }

  render() {

    return (
      <>
        <StyledHeader>
          <FaArrowLeft style={{color: '#FFF'}} data-test-id="go-back" onClick={this.goBack} />
          <h1 style={styles.headerTitle}>Ad Details</h1>
        </StyledHeader>

        <DetailedScreenBox>
          <List className='list-items'>
            <ListItem>
              <ListItemText 
                id="region_name"
                primary={"Region"} 
                secondary={this.getFieldValue("region")} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText
                key="screen_name" 
                primary={"Screen Name"} 
                secondary={this.getFieldValue("screen_name")} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"Customer Name"} 
                id="customer_name"
                secondary={`${this.getFieldValue("customer_name")}`} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"File Name"} 
                secondary={`${this.getFieldValue("file_name")}`} 
                id="file_name"
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                id="media_format"
                primary={"Media Format"} 
                secondary={this.getFieldValue('media_format')} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"Start Date"} 
                id="start_date"
                secondary={moment(this.getFieldValue('start_date'), 'DD-MM-YYYY').format('DD.MM.YYYY')} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"End Date"} 
                id="end_date"
                secondary={moment(this.getFieldValue('end_date'), 'DD-MM-YYYY').format('DD.MM.YYYY')} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"Total Amount"} 
                id="total_amount"
                secondary={`$ ${this.getFieldValue("total_amount")}`} 
              />
            </ListItem>
          </List>
          <Box
            sx={{ position: 'fixed', 
              boxSizing: 'border-box',
            }} 
            style={styles.buttonArea}
            >
          <ButtonWrapper>
          <GoBackToScreenButton onClick={this.goToScreen}>
            Go to Screens
          </GoBackToScreenButton>
          </ButtonWrapper>
          </Box>
        </DetailedScreenBox>
      </>
    );
  }
}

export default ManagerAdDetails;

const DetailedScreenBox = styled(Box)({
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  padding: "16px",
  paddingTop: "8px",
  paddingBottom: "130px",
  height: 'inherit',

  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const StyledHeader = styled(Box)({
  position: 'sticky',
  top: 0,
  boxShadow: "none",
  zIndex: 999,
  padding: "20px 16px",
  paddingBottom: '0px',
  color: "#FFF",
  borderBottom: "1px solid #64748B",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'
});

const GoBackToScreenButton = styled(Button)({
  padding: '16px 0px 16px 0px',
  gap: '8px',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  fontWeight: 'bold',
  fontSize: '16px',
  width: '90%',
  margin:"auto",
  textTransform: 'none',
})


const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 0 10px 0',
  width: '100%',
});