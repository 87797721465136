import React from 'react';

import { 
    styled,
    Box,
    List,
    ListItem,
    ListItemText,
    Button,
    AppBar
} from '@material-ui/core';
import { FaArrowLeft } from "react-icons/fa";
import { get } from '../commonTable/table';


import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from '../../../framework/src/RunEngine';
import ConfirmationDrawer from '../ConfirmationDrawer';

export const configJSON = require("../config");

const styles = {
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
  fileNameTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    'text-align': 'left',
    color: '#FFF',
    marginTop: '15px',
    marginBottom: '10px',
  },
  buttonArea: {
    bottom: '18px',
    width: '100%',
    maxWidth: 'calc(100% - 40px)',
  },
  mediaView: {
    zIndex: 9,
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    paddingTop: '100px',
  }
};

export interface Props {
  navigation: any;
}

interface SS {
  navigation: any;
}

class ManagerAdDetails extends BlockComponent<Props, any, SS> {
  getAdDataApiID: string = '';
  removeAdApiID: string = '';

  constructor(props: any) {
    super(props);

		this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      modalAdd: false,
			fileDetailData: {},
      viewAd: false,
      errorMessage: '',
      screenUrlCopy:false,
      duration: null,
      removeData: false,
      removeAdLoader: false,
    };
  }

	async componentDidMount() {
    super.componentDidMount();

		this.getAdDetails();
	}

	apiCall = async ({
		method, endPoint, contentType, body, isFormData
	}: any) => {
		const token = await getStorageData("token");
    const header = {
      "Content-Type": contentType,
      token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isFormData ? body : JSON.stringify(body)
    );

		runEngine.sendMessage(requestMessage.id, requestMessage);

		return requestMessage;
	}

	getAdDetails = async () => {
    const adId = this.props.navigation.getParam('adId');

		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpGetMethod, 
			endPoint: `${configJSON.getAdDetailsApiEndPoint}${adId}`, 
			contentType: "application/json",
		})

		this.getAdDataApiID = GetRegionAPI.messageId;
	}

  getFieldValue = (keyValue: string): string => {
    return get(this.state.fileDetailData, `attributes.${keyValue}`) || '-'
  }


	goBack = () => {
		this.props.navigation.goBack()
	}

  goToScreen = () => {
		this.props.navigation.navigate('LocalListingAds')
	}

	async receive(from: string, message: Message) { 
		let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
		const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

		if(apiRequestCallId == this.getAdDataApiID){ 
      if(!responseJson.errors && responseJson.file) this.setState({fileDetailData: responseJson.file.data});
      else {this.setState({fileDetailData: {}})}
    } else if(apiRequestCallId == this.removeAdApiID){ 
      if(!responseJson.errors) {
        this.setState({removeAdLoader: false});
        this.handleRemoveData();
        setStorageData('alert', JSON.stringify({
          message: 'Ad has been successfully removed'
        }))
        this.goToScreen();
      }
    }
	}

  updateVideoDetails = () => {
    const videoElement = document.getElementById("previewVideo") as HTMLVideoElement;
    if (videoElement) {
        const blob = new Blob([videoElement.src], { type: this.getFieldValue("media_format") });
        const reader = new FileReader();

        reader.onload = (event) => {
          this.setState({
            duration: `${Math.floor(videoElement.duration)} seconds`,
          });
        };
        reader.readAsArrayBuffer(blob); 
    }
  };

  handleRemoveData = () => {
    this.setState({removeData: !this.state.removeData})
  }

  handleSuccess = async () => {
    this.setState({removeAdLoader: true})
    const adId = this.props.navigation.getParam('adId');
		const GetRegionAPI = await this.apiCall({
			method: configJSON.httpPostMethod, 
			endPoint: `${configJSON.removeAdApiEndPoint}`, 
			contentType: "application/json",
      body: {
        "screen_file_id": adId
      },
      isFormData: false
		})

		this.removeAdApiID = GetRegionAPI.messageId;
  }

  render() {

    return (
      <>
        <StyledHeader>
          <FaArrowLeft style={{color: '#FFF'}} data-test-id="go-back" onClick={this.goBack} />
          <h1 style={styles.headerTitle}>View Ad</h1>
        </StyledHeader>
        
        <Box style={{...styles.mediaView}}>
          {this.getFieldValue("file_name") && this.getFieldValue("file") ?
            <>
              {this.getFieldValue("media_format").toLowerCase().includes('video') ? 
                <video
                  id="previewVideo"
                  controls
                  playsInline
                  loop
                  autoPlay
                  className="video-player"
                  style={{width:'100%', objectFit:'cover'}}
                  onLoadedMetadata={this.updateVideoDetails}
                >
                  <source src={this.getFieldValue("file")} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                :
                <img src={this.getFieldValue("file")} alt={this.getFieldValue("file_name")} style={{width:'100%', objectFit:'cover', background: 'transparent'}}/>
              }
            </>
          : null}
        </Box>
        <DetailedScreenBox>
          <h1 style={styles.fileNameTitle}>{this.getFieldValue("file_name")}</h1>
          <List className='list-items'>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                id="media_format"
                primary={"Media Format"} 
                secondary={this.getFieldValue('media_format')} 
              />
            </ListItem>
            <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"File Size"} 
                id="start_date"
                secondary={this.getFieldValue("file_size")} 
              />
            </ListItem>
            {this.state.duration && <ListItem style={{marginLeft: 0}}>
              <ListItemText 
                primary={"Duration"} 
                id="end_date"
                secondary={`${this.state.duration}`} 
              />
            </ListItem>  || null}
          </List>
          <Box
            sx={{ position: 'fixed', 
              boxSizing: 'border-box',
            }} 
            style={styles.buttonArea}
            >
            <CancelButton
              fullWidth
              variant="text"
              onClick={this.goBack}
            >
              Cancel
            </CancelButton>
            <GoBackToScreenButton fullWidth onClick={this.handleRemoveData}>
              Remove
            </GoBackToScreenButton>
          </Box>
        </DetailedScreenBox>

        <ConfirmationDrawer 
          navigation={this.props.navigation} 
          open={this.state.removeData}
          handleOpen={this.handleRemoveData}
          handleCancel={this.handleRemoveData}
          handleSuccess={this.handleSuccess}
          title={"Are you sure you want to remove the Ad name ?"}
          activeButtonText={"Confirm"}
          activeButtonLoader={this.state.removeAdLoader}
        />
      </>
    );
  }
}

export default ManagerAdDetails;

const DetailedScreenBox = styled(Box)({
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
  padding: "16px",
  paddingTop: "8px",
  paddingBottom: "160px",
  height: 'inherit',

  "& .MuiListItem-gutters": {
    paddingLeft: '0px',
    paddingRight: '0px',
    borderBottom: '1px solid #334155',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 'bold',
    color: '#CBD5E1',
    fontSize: '14px',
  },
  '& .MuiListItemText-secondary': {
    color: '#94A3B8',
    fontSize: '16px',
  },
});

const StyledHeader = styled(AppBar)({
  boxShadow: "none",
  zIndex: 999,
  padding: "20px 16px",
  paddingBottom: '0px',
  color: "#FFF",
  borderBottom: "1px solid #64748B",
  background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)'
});

const CancelButton = styled(Button)({
  padding: '12px 0px',
  borderRadius: '8px',
  color: "#ffffff",
  background: "#000",
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'none',
  marginBottom: '8px',
})

const GoBackToScreenButton = styled(Button)({
  padding: '12px 0px',
  borderRadius: '8px',
  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
  fontWeight: 'bold',
  fontSize: '16px',
  textTransform: 'none',
})