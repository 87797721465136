import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { master_card_icon, visa_dark_card_icon } from "./assets";
import { CardDetailType, paymentIntentType } from '../../../components/src/CollectPayment';
import { getToken } from "../../utilities/src/RegExRequired";
import { GenericApiCall } from "../../utilities/src/GenericApiCall";

// Customizable Area Start
import React from "react";
const baseURL = require('../../../framework/src/config').baseURL;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  cards?: any[];
  cardDetails?: any;
  token?: string;
  Username?: string;
  eiditingCard?: boolean;
  isPaymentDrawer?: boolean;
  data?: any;
  handlePaymentDrawer?: () => void;
  handlePostCreation?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addPaymentMethod: string;
  cardNumber: any;
  cardHolderName: string;
  expiryDate: any | null | Date;
  cvv: any;
  cardDetails: any;
  cardDetailsProps: any;
  getCardTokenId: any;
  cardType: number | string;
  postPaymentStatus: boolean;
  incorrectCardNumber: boolean;
  isAllFieldFill: boolean;
  isEditInputField: boolean;
  isUpdateMessageStatus: boolean;
  isDeleteStatus: boolean;
  isEditPaymentPageTitle : boolean;
  openModalPopup : boolean;
  serverError : boolean;
  isLoading : boolean;
  cardDataDetails: CardDetailType;
  savedCardList: CardDetailType[];
  paymentIntentResponse: paymentIntentType;
  saveStripeKay:string;
  isLoadingCardDetails: any;
  // Customizable Area End
}

interface MyComponentState {
  isUpdateMessageStatus: boolean;
  postPaymentStatus: boolean;
  incorrectCardNumber: boolean;
  isDeleteStatus: boolean;
  serverError : boolean;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CardDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCardDetailsId: string = "";
  getSavedCardListId: string = "";
  getSavedstripeKayId: string = "";
  submitRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      addPaymentMethod: '',
      cardDetails: [], // all cards data
      cardDetailsProps: {}, // for editing and deleting card
      getCardTokenId: '',
      cardNumber: '',
      cardHolderName: '',
      expiryDate: null,
      cvv: null,
      cardType: '',
      postPaymentStatus: false,
      incorrectCardNumber: false,
      isAllFieldFill: false,
      isEditInputField: true,
      isUpdateMessageStatus: false,
      isDeleteStatus: false,
      isEditPaymentPageTitle : false,
      openModalPopup : false,
      serverError : false,
      isLoading : false,
      savedCardList: [],
      cardDataDetails: {
        id: '1', 
        type: 'payment_method', 
        attributes: { card: { "last4": "****", "brand": "" } }
      },
      paymentIntentResponse: {},
      saveStripeKay: '',
      isLoadingCardDetails: null
      // Customizable Area End
    };

    this.submitRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ cardDetails: responseJson?.data || [], isLoading: false })
    }
    if (apiRequestCallId === this.getSavedCardListId) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleSavedCardApiResponse(responseJson);
    }
    if (apiRequestCallId === this.getSavedstripeKayId){      
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleStripeKeyApiResponse(responseJson);
    }
    if ( apiRequestCallId === this.getCardDetailsId) {
      this.handleGetCardListResp()
    } else {
      this.setState({isLoading: false})
      this.setState({isLoadingCardDetails: true})
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCardLists()
    this.getSavedCardList();
    this.getSavedStripekey()
    // Customizable Area End
  }

  async componentDidUpdate() {
    const resetState = (key: keyof MyComponentState) => {
      setTimeout(() => {
        this.setState({ [key]: false } as Pick<MyComponentState, keyof MyComponentState>);
      }, 5000);
    };
  
    // Customizable Area Start
    if (this.state.isUpdateMessageStatus) {
      resetState("isUpdateMessageStatus");
    }
  
    if (this.state.postPaymentStatus) {
      resetState("postPaymentStatus");
    }
  
    if (this.state.incorrectCardNumber) {
      resetState("incorrectCardNumber");
    }
  
    if (this.state.isDeleteStatus) {
      resetState("isDeleteStatus");
    }

    if (this.state.serverError) {
      resetState("serverError");
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  callPaymentMethodsAPI() {
    // Prepare the request body
    const requestBody = {
      payment_method: {
        "token": this.state.getCardTokenId.id || "",
        "name": this.state.cardHolderName
      }
    };
    const authToken = window.localStorage.getItem("token");
    // Make API call with fetch
    fetch(baseURL + '/bx_block_stripe_integration/payment_methods', {
      method: 'POST', // Assuming you want to make a POST request
      headers: {
        'Content-Type': 'application/json',
        "token": `${authToken || ""}`
      },
      body: JSON.stringify(requestBody) // Convert the object to JSON string
    })
      .then(response => response.json())
      .then(data => {
        // Handle API response here
        if (data?.message) {
          this.setState({ postPaymentStatus: this.state.cardDetails.length === 7 ? false : true, cardNumber: " ", cardHolderName: " ", expiryDate: " ", cvv: " ", addPaymentMethod: "",  isAllFieldFill: false });
          this.addButtonEnableDisable()
          this.getCardLists();
        }
        else {
          this.setState({ serverError: true , isAllFieldFill: false})
        }
      })
      .catch(error => {
        // Handle errors here
        this.setState({ serverError: true })
      });
  }

  callUpdatePaymentMethodsAPI = () => {
    // Prepare the request body
    const requestBody = {
      payment_method: {
        "payment_method_id": this.state.cardDetailsProps?.id,
        "token": this.state.getCardTokenId.id || "",
        "name": this.state.cardHolderName
      }
    };
    const authToken = window.localStorage.getItem("token");
    // Make API call with fetch
    fetch(baseURL + '/bx_block_stripe_integration/payment_methods/edit_payment_method', {
      method: 'PUT', // Assuming you want to make a POST request
      headers: {
        'Content-Type': 'application/json',
        "token": `${authToken || ""}`
      },
      body: JSON.stringify(requestBody) // Convert the object to JSON string
    })
      .then(response => response.json())
      .then(data => {
        // Handle API response here
        if (data?.message) {
          this.setState({ isUpdateMessageStatus: true,isEditPaymentPageTitle : false, addPaymentMethod: "", cardNumber: " ", cardHolderName: " ", expiryDate: " ", cvv: " " , isAllFieldFill: false});
          this.addButtonEnableDisable();
          this.getCardLists();
        }
        else {
          this.setState({ serverError: true,isAllFieldFill: false})
        }
      })
      .catch(error => {
        // Handle errors here
        this.setState({ serverError: true })
      });
  }

  handleGetCardListResp = () => {
      this.setState({isLoading: false})
      this.setState({isLoadingCardDetails: true})
  }

  getSavedCardList = () => {
    let token:any = getToken()
    let requestMesg = GenericApiCall(
      configJSON.apiContentType, 
      configJSON.getPaymentMethod, 
      configJSON.SavedAccountEndPoint,
      token)
    this.getSavedCardListId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  getSavedStripekey = () => {
    let token:any = getToken()
    let requestMesg = GenericApiCall(
      configJSON.apiContentType, 
      configJSON.getPaymentMethod, 
      configJSON.PaymentMethodStripeKeyEndPoint,
      token)
    this.getSavedstripeKayId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }

  handleSavedCardApiResponse(responseJson: {data: CardDetailType[]}){
    const excludeDates = responseJson.data;
    this.setState({ savedCardList: excludeDates});
    if(excludeDates?.length) this.setState({ cardDataDetails: excludeDates[0] });
  };

  handleStripeKeyApiResponse(responseJson: {stripe_publish_key: string}){
    const excludeDates = responseJson.stripe_publish_key;
    this.setState({ saveStripeKay: excludeDates});  };
  handleClickBack = () => {
    if (this.state.addPaymentMethod === "") { 
      this.navigateBack() 
    } else {
      this.setState({
        addPaymentMethod: "",
        isAllFieldFill: false,
        cardNumber: "",
        cardHolderName: "",
        cvv: "",
        expiryDate: "",
        isEditPaymentPageTitle: false
      });
    }
  }

  navigateBack = () => {
    this.props.navigation.goBack()
  }

  handleCardNumber = (e: any) => {
    // added card 19 digit limit  
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 19) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 19);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }
    this.setState({ cardNumber: spacedNumber });
  };

  handleCardHolderName = (e: any) => {
    const { value } = e.target;
    // Replace any characters that are not letters or spaces with an empty string
    const newValue = value.replace(/[^A-Za-z\s]/g, '');
    
    // Check if the length of the newValue is within the 24 characters limit
    if (newValue.length <= 24) {
      this.setState({ cardHolderName: newValue });
    }
  }  

  handleExpiryDate(e: any) {
    let input = e.target.value;
    input = input.replace(/\D/g, '');

    if (input.length > 2) {
      input = input.slice(0, 2) + '/' + input.slice(2);
    }

    const parts = input.split('/');
    if (parts.length === 2) {
      parts[1] = parts[1].slice(0, 4);
      input = parts.join('/');
    }

    if (parts?.[0].length <= 2 || parts?.[1].length <= 4) {
      this.setState({
        expiryDate: input,
      });
    }
  }

  handleCvv = (event: any) => {
    const inputVal = event.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 4) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 4);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }
    this.setState({ cvv: Number(spacedNumber) });
  }

  addButtonEnableDisable = () => {
    if (this.state.cardNumber && this.state.cardHolderName && this.state.expiryDate && this.state.cvv) {
      this.setState({ isAllFieldFill: true })
    } else {
      this.setState({ isAllFieldFill: false })
    }
  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    this.submitRef.current.disabled = true;
    if (this.state.cardDetailsProps?.id) {
      this.updateCardDetails(event);
    } else {
      this.addPaymentMethodFunc(event);
    }
  }

  getCardIconSrc = () => {
    const { cardNumber } = this.state;
    if (cardNumber.startsWith("2") || cardNumber.startsWith("5")) {
      return master_card_icon;
    } else if (cardNumber.startsWith("4")) {
      return visa_dark_card_icon;
    } else {
      return "";
    }
  };

  getCardLists = () => {
      this.setState({isLoadingCardDetails: true})
    const authToken = window.localStorage.getItem("token");
    const headers = {
      "Content-Type": configJSON.cardDetailsContentType,
      token: authToken
    };

    const getRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCardDetailsId = getRequestMessage.messageId;


    getRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCardDetailsEndPoints
    );

    getRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPaymentMethod
    );

    getRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(getRequestMessage.id, getRequestMessage);
  };

  
   handlePayment = (param : string) => {
    const privateKey = "pk_test_51Oe1FFBtafrPJhIsUcT8fEo4CuVo17MJwz0ckDeCIlUbthVDidYsF8bdph5N4UdtMpDj32XzlQC6DmVeII8GHx1J00Wrujrnhb";
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${privateKey}`
      };
      const [month, year] = this.state.expiryDate.split('/');
      const formData = new URLSearchParams({
        'card[number]': this.state.cardNumber,
        'card[exp_month]': month,
        'card[exp_year]': year,
        'card[cvc]': this.state.cvv
      });
      const requestBody = formData.toString();
  
      fetch("https://api.stripe.com/v1/tokens", {
        method: 'POST',
        headers: headers,
        body: requestBody
      })
        .then(response => response.json())
        .then(data => {
          if (data?.id) {
            this.setState({ getCardTokenId: data })
            if (this.state.getCardTokenId?.id) {
              // Call your API here
              if(param === 'add'){
                this.callPaymentMethodsAPI();
              }
               else{
                this.callUpdatePaymentMethodsAPI();
               }
            }
          }
          if (data?.error) {
            this.setState({ incorrectCardNumber: true })
            this.submitRef.current.disabled = false;
          }
  
        })
        .catch(error => {
          this.setState({ incorrectCardNumber: true })
            this.submitRef.current.disabled = false;
          // Handle errors here
        });
  }
  

  addPaymentMethodFunc = (event: any) => {
    this.handlePayment('add');
  }

  updateCardDetails = (event: any) => {
    this.handlePayment("update");
  }

  handleClickOpen = () => {
    this.setState({openModalPopup : true})
  };

  handleClose = () => {
    this.setState({openModalPopup : false})
  };

  confirmPopUp = () => {
    this.handleClickOpen();
  }

  handleDashboard = () => {
    this.props.navigation.navigate('HomeDashboard')
  }
  handleGoToFiles = () => {
      this.props.navigation.navigate('LocalListingFiles')
  }

  getScreenType = () => {
      return window.location.pathname;
  }

  deletecard =  () => {
    this.handleClose();
    const requestBody = {
      "payment_method_id": this?.state?.cardDetailsProps?.id,
    };
    const authToken = window.localStorage.getItem("token");
    // Make API call with fetch
    fetch(baseURL + '/bx_block_stripe_integration/payment_methods/delete_payment_method', {
      method: 'DELETE', // Assuming you want to make a DELETE request
      headers: {
        'Content-Type': 'application/json',
        "token": `${authToken || ''}`
      },
      body: JSON.stringify(requestBody) // Convert the object to JSON string
    })
      .then(response => response.json())
      .then(data => {
        // Handle API response here
        if (data?.message) {
          this.setState({ isDeleteStatus: true, addPaymentMethod: "" });
          this.getCardLists();
        }
        else {
          this.setState({ serverError: true })
        }
      })
      .catch(error => {
        // Handle errors here
        this.setState({ serverError: true })
      });
  }

}
  // Customizable Area End
