import React, {useState} from 'react'
import {
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Box,
  Button,
  Typography
} from '@material-ui/core'
import { Menu, ArrowBack, ChevronRight, NotificationsNoneOutlined, Close} from '@material-ui/icons';

import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { GenericApiCall } from "../../blocks/utilities/src/GenericApiCall";
import { getStorageData, removeStorageData, logoutUser } from "../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}
interface S {
  enableField: boolean;
  showDelete: boolean;
}
interface SS {
  id: string;
}

interface DrawerType {
  pageName:string;
  pagePath: string;
  noArrow?: boolean;
}

export default class AppHeader extends BlockComponent<Props, S, SS> {

  logoutApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      enableField: false,
      showDelete: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId == this.logoutApiCallId) {
          this.handleLogoutResponse(responseJson)
        }
      }
    }
  }

  handleLogoutResponse = (responseJson: any) => {
    logoutUser()
    window.location.href = '/EmailAccountLoginBlock'
    window.history.replaceState(null, '', '/EmailAccountLoginBlock')
  }
  
  handleDrawerOpen = () => {
    this.setState({enableField:true});
  };

  handleDrawerClose = () => {
    this.setState({enableField:false});
  };

  navigateBack=()=>{
    this.props.navigation.goBack()
  }

  userRoleId = () => {
    const userStore = localStorage.getItem("userData");
    return userStore ? JSON.parse(userStore)?.roleName : null;
  };

  getDrawerList = (): {pageName: string, pagePath: string, noArrow?: boolean}[] => {
    const roleName = this.userRoleId();
    if(roleName === 'screen_manager') return configJSON.drawerMenuScreenManager
    else return configJSON.drawerMenu
  }

  handleLogout = () => {
    this.setState({showDelete: !this.state.showDelete})
  }

  navigateToPage=(pageName:string, value:string)=>{
    if(pageName === 'Logout') {
      this.handleDrawerClose()
      this.handleLogout();
      return;
    };
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    const raiseMsg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    message.addData(getName(MessageEnum.NavigationRaiseMessage),raiseMsg)
    this.send(message);
  }
  
  closeAlertPopUp = () => {
    this.setState({showDelete: false})
  }
  performActionOnAccount = async(type: string) => {
    const token = await getStorageData("token");
    removeStorageData('token')
    let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.logoutApiMethod, configJSON.logoutApiEndPoint, token)
    this.logoutApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
  

  render() {
    return (
      <>
        <AppBar
          position="fixed"
          style={{backgroundColor:'#181719', boxShadow:'none'}}
        >
          <Toolbar style={{minHeight: '70px'}}>
            <Grid container >
              <Grid  xs item style={{display:'grid',justifyContent:'start'}}>
                <IconButton
                  aria-label="open drawer"
                  edge="start"
                  data-test-id="drawer"
                  onClick={this.handleDrawerOpen}
                >
                  <Menu style={{color:'#FFF'}} />
                </IconButton>
              </Grid>
              <Grid item  xs={9} sm style={{textAlign:'center'}}>
                <img src={require('../assets/Image_logo_white.png')} style={{height:'3em', width:'7.6em'}}/>
              </Grid>
              <Grid item xs  style={{justifyContent:'end',display:'grid'}} container>
                <IconButton
                  edge="start"
                  aria-label="notification_icon"
                  onClick={() => this.props.navigation.navigate('notifications')}
                >
                  <NotificationsNoneOutlined style={{color:'#FFF'}} />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          className="drawerMenu"
          open={this.state.enableField}
          anchor="left"
          style={{width:'80%'}}
          PaperProps={{style:{backgroundColor: 'rgba(0, 0, 0, 0.7)', width: '80%'}}}
          onClose={this.handleDrawerClose}
        >
          <div style={{display:'flex',justifyContent:'space-between',padding:'20px 0px 5px'}}>
            <img src={require('../assets/Image_logo_white.png')} style={{height:'3em',width:'7.6em',marginLeft:'25px'}}/>
            <IconButton 
              data-test-id="drawer_close" 
              onClick={this.handleDrawerClose}
            >
              <Close style={{color:'#FFF'}}  />
            </IconButton>
          </div>
          <List style={{color: '#E2E8F0'}}>
            {this.getDrawerList().map((text: DrawerType, index: number) => (
              <ListItem 
                key={`${text.pageName}${index}item`} 
                data-test-id="drawer_menu_click" button 
                style={{paddingRight:'10px',paddingLeft:'32px'}}
                onClick={()=>this.navigateToPage(text.pagePath,text.pageName)} 
              >
                <ListItemText primary={text.pageName} /> 
                {!text.noArrow && <ListItemIcon style={{minWidth:'10px'}}><ChevronRight /></ListItemIcon>}
              </ListItem>
            ))}
          </List>
        
        </Drawer>

        <Drawer 
          className="confirmationAlert"
          anchor={'bottom'}
          open={this.state.showDelete}
          onClose={this.closeAlertPopUp}
          PaperProps={{style:{backgroundColor: 'rgba(0, 0, 0, 1)', width: '100%', borderTopRightRadius: '24px'}}} 
        >
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="text" data-test-id="closeBtn" style={{ margin: '20px 3px', padding: '0px' }} onClick={() => this.closeAlertPopUp()}><Close style={{ fontSize: '24px', color: '#FFF' }} /></Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>

            <Typography component="div" style={{ fontSize: '18px', fontWeight: 700, margin: '12 0 0', color: '#FFF', lineHeight: '26px' }}>
              {configJSON.logoutAlert.title}
            </Typography>
            <Box style={{ width: '100%', bottom: '24px', boxSizing: 'border-box'}}>
              <Button
                fullWidth
                key={'cancel'}
                className={'textBtn'}
                variant={'text'}
                data-test-id={`${'cancel'}Btn`}
                onClick={() => this.handleLogout()}
                style={{
                  padding: '16px 16px',
                  borderRadius: '8px',
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  textTransform: 'capitalize',
                  width: '100%',
                  color: '#FFF'
                }}
              >
                Cancel
              </Button>
              <Button
                key={'logout'}
                className={'conatinedBtn'}
                data-test-id={`${'logout'}Btn`}
                variant={'contained'}
                fullWidth
                style={{
                  padding: '16px 16px',
                  borderRadius: '8px',
                  background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)',
                  display: 'flex',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  width: '100%',
                  textTransform: 'none'
                }}
                onClick={() => this.performActionOnAccount('logout')}
              >
                Log out
              </Button>
            </Box>
          </Box>
        </Drawer>
      </>
    )
  }

}
