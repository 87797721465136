import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import Loader from "../../../../packages/components/src/Loader";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    },
  },
  typography: {
    h5: {
      color: "red",
      fontWeight: 600,
    },
    h1: {
      fontWeight: "bold",
      color: "green",
    },

    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
    body2: {
      fontSize: "0.7em",
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    MuiInputBase: {
      input: {
        padding: "10.5px",

        "&:focus": {
          borderRadius: "2px",
        },
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10.5px",
      },
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        height: "35px",
        width: "17.1%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "0px 5px 5px 9px",
      },
    },
    MuiButton: {
      root: {
        "&.nextButton": {
          fontWeight: "700",
          background: "linear-gradient(#FFFFFF 100%,#919191 100%)",
          borderRadius: "8px",
          color: "black",
          textTransform: "none",
          marginBottom: "1em",
          padding: "0.92em 0px",
        },
        "&.skipButton": {
          color: "white",
          background: "none",
          fontWeight: "700",
          margin: "2em 0px 1em",
          textTransform: "none",
          padding: "0.92em 0px",
          "&:hover": {
            background: "none",
          },
        },
        "&.finishButton": {
          background: "linear-gradient(#FFFFFF 100%,#919191 100%)",
          color: "black",
          fontWeight: "700",
          textTransform: "none",
          borderRadius: "8px",
          padding: "0.92em 0px",
          width: "48%",
        },
        "&.backButton": {
          color: "white",
          fontWeight: "700",
          width: "48%",
          background: "none",
          border: "2px solid white",
          borderRadius: "8px",
          textTransform: "none",
          padding: "0.92em 0px",
          "&:hover": {
            background: "none",
          },
        },
      },
    },
    MuiMobileStepper: {
      dotActive: {
        backgroundColor: "#F7F7F7",
      },
      dot: {
        backgroundColor: "#94A3B8",
      },
    },
  },
});
// Customizable Area End

import OnboardingguideController, {
  Props,
  configJSON
} from "./OnboardingguideController";

export default class Onboardingguide extends OnboardingguideController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.container}>
          <Box sx={webStyle.imageContainer}>
          {this.state.isLoading ? <Loader loading={true} /> : null}
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
              }}
              onLoadStart={() => this.setState({isLoading: true})}
              onLoad={() => {
                  this.setState({isLoading: false})
              }}
              src={this.state.onboardingSteps[this.state.activeStep].imgPath}
              alt="InstaNIL Background"
            />
          </Box>
          <Box sx={webStyle.mainWrapper}>
            <div style={{ padding: theme.spacing(0.5) }}>
              <Typography
                style={{
                  fontSize: "1.6em",
                  fontWeight: "700",
                  margin: "8px 0 12px 8px",
                  color: "white",
                }}
              >
                {this.state.onboardingSteps[this.state.activeStep].title}
              </Typography>
              <Typography
                style={{
                  fontSize: "0.85em",
                  color: "white",
                  fontWeight: "400",
                  margin: "0 0 12px 12px",
                }}
                dangerouslySetInnerHTML={{
                  __html: this.state.onboardingSteps[
                    this.state.activeStep
                  ].body.replace(/\n/g, "<br />"),
                }}
              />
              <MobileStepper
                style={{ background: "none" }}
                data-test-id="mobileStepper"
                steps={this.state.maxSteps}
                position="static"
                variant="dots"
                activeStep={this.state.activeStep}
                nextButton={<Typography />}
                backButton={<Typography />}
              />
              {this.state.activeStep !== this.state.maxSteps - 1 ? (
                <div style={{ marginTop: "-25px" }}>
                  <Button
                    data-test-id="skipBtn"
                    variant="text"
                    color="primary"
                    onClick={this.handleSkip}
                    size="large"
                    fullWidth
                    className="skipButton"
                  >
                    Skip
                  </Button>

                  <Button
                    data-test-id="nextBtn"
                    variant="contained"
                    onClick={this.handleNext}
                    size="large"
                    fullWidth
                    className="nextButton"
                  >
                    Next
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: "48px 0px",
                  }}
                >
                  <Button
                    data-test-id="backBtn"
                    variant="contained"
                    onClick={this.handleBack}
                    size="large"
                    fullWidth
                    className="backButton"
                  >
                    Back
                  </Button>

                  <Button
                    data-test-id="finishBtn"
                    variant="contained"
                    onClick={this.handleFinish}
                    size="large"
                    fullWidth
                    className="finishButton"
                  >
                    Finish
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter",
    flexDirection: "column",
    paddingBottom: "1em",
    height: "100%",
    alignItems: "center",
    background:
      "linear-gradient(179.68deg, rgba(56, 124, 177, 0) 41.98%, #122A45 59.59%, #112035 99.72%)",
    position: "absolute",
    justifyContent: "flex-end",
    bottom: 0,
    padding: "0 16px",
    minHeight: "calc(100vh - 60%)",
  },
  container: {
    position: "relative",
    height: "100vh",
    width: "100%",
    fontFamily: "'Arial', sans-serif",
    color: "#fff",
  },
  imageContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
};
// Customizable Area End
