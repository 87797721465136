Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings";
exports.labelBodyText = "settings Body";

exports.btnExampleTitle = "CLICK ME";

exports.accountList = [
  {title:'Card details',path:'CardList',icon:'card',rightIcon:false,subHeader:''},
  {title:'Currency',path:'',icon:'dollar',rightIcon:false,subHeader:'US dollar'},
  {title:'Personal details',path:'CustomisableUserProfiles',icon:'person',rightIcon:true,subHeader:''},
  {title:'Change password',path:'ChangePassword',icon:'lock',rightIcon:true,subHeader:''},
  // {title:'Change Email',path:'Settings2',icon:'mail',rightIcon:true,subHeader:''}
]
exports.orderDetails=[{ title: 'Order history', path: 'LocalListingFiles', icon: 'history', rightIcon: true, subHeader: '' },
];
exports.notifications=[{ title: 'Weekly updates', selected: false, value:'update'},
{ title: 'Special offers', selected: false, value:'offer'}
];
exports.accountActions=[{ title: 'Log out', icon: 'logout', actionToTake:'logout' },
{ title: 'Delete account', icon: 'delete', actionToTake:'delete' }
]
exports.deleteAlert={
  title:'Are you sure you want to delete your account?',
  text:'This action can’t be undone and all the data stored in your account won’t be accessible anymore.',
  buttons:[
    {name:'Delete account',type:'text',action:'delete',class:'textBtn'},
    {name:'Keep account',type:'contained',action:'cancel',class:'conatinedBtn'}
  ]
}
exports.logoutAlert={
  title:'Are you sure you want to log out of your account?',
  text:'',
  buttons:[
    {name:'Cancel',type:'text',action:'cancel',class:'textBtn'},
    {name:'Log out',type:'contained',action:'logout',class:'conatinedBtn'}
  ]
}
exports.weeklyUpdates={
  title:'Are you sure you want to enable or disable weekly update notifications?',
  text:'',
  buttons:[
    {name:'No',type:'text',action:'cancel',class:'textBtn'},
    {name:'Yes',type:'contained',action:'update',class:'conatinedBtn'},

  ]
};
exports.specialOffers={
  title:'Are you sure you want to enable or disable special offer notifications?',
  text:'',
  buttons:[
    {name:'No',type:'text',action:'cancel',class:'textBtn'},
    {name:'Yes',type:'contained',action:'offer',class:'conatinedBtn'},

  ]
};
exports.logoutApiEndPoint = '/account_block/accounts/logout'
exports.deleteApiEndPoint = '/account_block/accounts/delete_account';
exports.deleteApiMethod = 'DELETE'
exports.patchApiMethod = 'PATCH'
exports.specialOffersApiEndPoint = '/bx_block_settings/notifications/special_offers'
exports.weeklyUpdatesApiEndPoint = "/bx_block_settings/notifications/weekly_updates"
exports.notificationsDataApiEndPoint = '/account_block/accounts/specific_account';
exports.updatedPasswordApiEndPoint = '/bx_block_profile/passwords'
// Customizable Area End