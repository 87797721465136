import React, { ReactElement, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { 
    
    Paper,
		Typography,
    IconButton,
    MenuItem,
    ListItemIcon,
    ListItemText,
    styled,
    CircularProgress,
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';

import MoreVert from '@material-ui/icons/MoreVert';
import { MdOutlineCheckCircle } from "react-icons/md";
import { CgMenuGridO } from "react-icons/cg";
import { BiCloudUpload } from "react-icons/bi";
import { LuMonitorOff } from "react-icons/lu";
import { get } from './table';
import { getStorageData } from "../../../framework/src/Utilities";

const styles = {
  paper: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    height: "calc(100vh - 140px)"
  },
  header: {
    display: 'flex',
    backgroundColor: 'transparent',
    color: '#94A3B8',
    padding: '16px 10px 8px 16px',
    borderBottom: "1px solid #334155",
  },
  headerCell: {
    flex: 1,
    'text-align': 'left',
    fontWeight: 'bold' as const,
    fontSize: '14px'
  },
	headerCellWithTwo: {
    flex: 1.5,
    'text-align': 'left',
    fontWeight: 'bold' as const,
    fontSize: '14px',
  },
	headerFilterCell: {
    flex: 0.5,
    'text-align': 'center',
    fontWeight: 'bold' as const,
    fontSize: '14px'
  },
  row: {
    display: 'flex',
    color: '#94A3B8',
    padding: '16px',
    background: "#01010180",
		borderBottom: "1px solid #334155",
    'align-items': 'center',
    gap: '20px'
  },
  cell: {
    flex: 1,
    'text-align': 'left',
    fontSize: '12px',
  },
	fullCell: {
    flex: 1,
    'text-align': 'center',
    fontSize: '12px',
  },
	cellWithTwo: {
    flex: 1.5,
    'text-align': 'left',
    fontSize: '12px',
  },
	cellMenu: {
    flex: 0.5,
    'text-align': 'right',
  },
  container: {
    flexGrow: 1,
    overflowY: 'auto' as const,
  },
	iconColor: { color: "#94A3B8" },
	svg: {
		color: "#94A3B8",
		height: "25px",
		width: "25px",
	},
    tableDataRow: {
      background: "#01010180",
      borderBottom: "1px solid #334155",
    },
    tableHeader: {
      background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    },
    headerStyles: {
      padding: '10px 8px',
      background: 'transparent'
    },
};

interface RowData {
  screenName: string;
  rate: string;
  status: string;
}

interface State {
  rows: RowData[];
}


type headerKeyTypes = {
	title: string;
	key: string;
	dataKey: string;
}
type objectDataType = {[key:string]: any};

type TablePropTypes = {
	headerKeys: headerKeyTypes[];
	tableData: any[];
	showFilter: boolean;
	moreMenuList: {title: string, key: string, icon: ReactElement}[];
	isLoading?: boolean;
	handleMoreItem?: (key: string, data: objectDataType) => void;
	handleHeaderFilter?: (key: string) => void;
  navigation?: any;
}

const headerFilterData = [{
  icon: <CgMenuGridO />,
  title: 'All',
  key: 'all'
}, {
  icon: <BiCloudUpload />,
  title: 'Active',
  key: 'active'
}, {
  icon: <MdOutlineCheckCircle />,
  title: 'Available',
  key: 'available'
}, {
  icon: <LuMonitorOff />,
  title: 'Unavailable',
  key: 'unavailable'
}]

export default function ScreenTable(props: TablePropTypes) {

    const {tableData, isLoading, handleMoreItem} = props;
    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
  
    };
    const emptyRows = props.tableData.length;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currantRowData, setCurrantRowData] = React.useState<objectDataType>({});

    const [userData, setUserData] = React.useState<any>();

    useEffect(() => {
      const getUserData = async () => {
        const userData = await getStorageData("userData", true);
        userData && setUserData(userData)
      }
      getUserData();

    }, [])
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, rowData: objectDataType) => {
        setAnchorEl(event.currentTarget);
        setCurrantRowData(rowData);
    };

    const navigateToPage = (path: string, params: any) => {
      props.navigation && props.navigation.navigate(path, params)
    }

    const handleManagerMoreMenu = (key: string) => {
      switch (key) {
        case 'Ad_details':
          navigateToPage(`ManagerAdDetail`, {adId: currantRowData?.id})
          break;
        case 'View_ad':
          navigateToPage(`ViewAd`, {adId: currantRowData?.id})
          break;
        
        default:
          break;
      }
    }
    
		const handleClose = () => {
        setAnchorEl(null);
        setCurrantRowData({});
    };
    
    
		const handleMenuItemClick = (key: string) => {
      if(userData && userData.roleName === 'screen_manager') {
        handleManagerMoreMenu(key);
      } else {
        handleMoreItem && handleMoreItem(key, currantRowData);
        handleClose();
      }
		}

    return (
      // <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Paper style={{...styles.paper, display: 'flex', flexDirection: 'column'}}>
        <div style={{position: 'sticky', top: 0, zIndex: 1 , display:"block"}}>
          <div style={{...styles.header}}>
            <div style={styles.headerCellWithTwo}>File Name</div>
            <div style={styles.headerCellWithTwo}>Upload Date</div>
            <div style={styles.headerCellWithTwo}>Screen Name</div>
            <div style={styles.headerFilterCell}></div>
          </div>
        </div>
        <div style={styles.container}>
          {tableData.map((row: any, index: number) => (
            <div style={styles.row} key={`screenData-${index}`}>
              <div style={styles.cellWithTwo}>{get(row, 'attributes.file_name')?.length > 10 ? `${get(row, 'attributes.file_name').slice(0, 10)}...` : get(row, 'attributes.file_name')}</div>
              <div style={styles.cellWithTwo}>{get(row, 'attributes.upload_date')}</div>
              <div style={styles.cellWithTwo}>{get(row, 'attributes.screen_name')?.length > 10 ? `${get(row, 'attributes.screen_name').slice(0, 10)}...` : get(row, 'attributes.screen_name')}</div>
							<div style={styles.cellMenu}>
								<IconButton
									aria-haspopup="true"
									style={{padding: 0}}
									onClick={(event) => handleMenuClick(event, row)}
								>
									<MoreVert style={styles.iconColor} />
								</IconButton>
								<StyledMenu
									id="customized-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									{props.moreMenuList.map((menu) => (currantRowData?.attributes?.status === 'unavailable' && menu.key === 'upload') ? null : (
										<StyledMenuItem key={menu.key} onClick={() => handleMenuItemClick(menu.key)}>
											<ListItemIcon style={{minWidth: "35px"}}>
												{menu.icon}
											</ListItemIcon>
											<StyledListItemText primary={menu.title} />
										</StyledMenuItem>
									))}
								</StyledMenu>
							</div>
            </div>
          ))}
					{!isLoading && !emptyRows && (
						<div style={{ ...styles.row, height: (53) * 1 }} >
							<div style={styles.fullCell}>
								No data
							</div>
						</div>
					)}
					{isLoading && (
						<div style={{ ...styles.row, height: (53) * 2, backgroundColor: 'transparent', border: 0 }}>
							<div style={styles.fullCell}>
								<CircularProgress style={styles.iconColor} />
							</div>
						</div>
					)}
        </div>
      </Paper>
      // </div>
    );
  
}

const StyledMenu = withStyles({
  paper: {
    background: "#000",
    color: "#94A3B8",
    borderRadius: "8px"
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)({
  color: '#94A3B8',
  minWidth: '180px',
  '& svg': {
    color: '#94A3B8',
    height: '25px',
    width: '25px',
  },
});

const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '14px',
  },
});