import React, { useEffect, useState, useRef } from 'react';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { useRunEngine } from '../../utilities/src/hooks/useRunEngine';
import { useBlockHelpers } from '../../utilities/src/hooks/useBlockHelpers';
import { getStorageData } from '../../../framework/src/Utilities';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { config } from './config.web';

import StripePaymentsView from './StripePaymentsView.web';

// Customizable Area Start
import moment from 'moment';
import { getToken as getAuthToken } from "../../utilities/src/RegExRequired";
import {CardDetailType, IInterFace} from '../../../components/src/CollectPayment';
import {IHandleAddFormData} from '../../../components/src/AddNewPaymentCard';

import { runEngine } from "../../../framework/src/RunEngine";

const master_card_icon = require("../assets/master_card_icon.png");
const visa_dark_card_icon = require("../assets/visa_dark_icon.png");

export const configJSON = require("./config");

export interface CardDataTypes {
	cardNumber: string;
	cardHolderName: string;
	expiryDate: string;
	cvv: string;
}
// Customizable Area End

export interface ViewProps {
	// Customizable Area Start
	testID: string;
	stripePromise: Promise<Stripe | null>;
	stripeClientSecret: string | undefined;
	errorString: string | undefined;
	setOrderNumber: (_: number) => void;
	orderNumber: number | undefined;
	actionResult: string | undefined;
	stripeInitialised: boolean;
	isInStripeCallback: boolean;
	onHandleSubmit: (event: React.MouseEvent<HTMLElement>, stripe: Stripe | null, stripeElements: StripeElements | null) => void;
	submitOrderNumberButtonViewProps: { value: string };
	submitPaymentButtonViewProps: { value: string };
	loadingViewProps: { value: string };
	orderIdViewProps: IInterFace;
	stripeMessageViewProps: { successValue: string; errorValue: string };
	// Customizable Area End
}

export interface ControllerProps {
	navigation: any;
	id: string;
	// Customizable Area Start
	// Customizable Area End
}

const subscribedMessages = [
	// Customizable Area Start
	MessageEnum.RestAPIResponceMessage,
	MessageEnum.SessionResponseMessage,
	// Customizable Area End
];

const StripePayments = ({ navigation, id }: ControllerProps) => {
	// Customizable Area Start
	const { cardDataDetails, setCardDataDetails, uploadScreenData, cardDetails, isPaymentDrawer, handlePaymentDrawer, handleMessage, saveStripeKay} = navigation;
	const getPaymentMethodsCallId = useRef<string>('');
	const getPaymentSuccessMethodsCallId = useRef<string>('');
	const getSavedCardListId = useRef<string>('');
	const saveNewCardId = useRef<string>('');
	const saveCardToServerId = useRef<string>('');
	let token:any = getAuthToken()
	// Customizable Area End

	// Customizable Area Start
	const params = new URLSearchParams(window.location.search);
	const { extractNetworkResponse } = useBlockHelpers();
	const returnedPaymentIntentClientSecret = params.get('payment_intent_client_secret');
	const isInStripeCallback = Boolean(returnedPaymentIntentClientSecret);
	const [orderNumber, setOrderNumber] = useState<number | undefined>(undefined);
	const [userAppAuthenticationToken, setUserAppAuthenticationToken] = useState<string | undefined>(undefined);
	const [stripePaymentIntentId, setStripePaymentIntentId] = useState<string | undefined>(undefined);
	const [stripeClientSecret, setStripeClientSecret] = useState<string | undefined>(undefined);
	const [paymentIntentId, setPaymentIntentId] = useState<string>('');
	const [stripeInitialised, setStripeInitialised] = useState<boolean>(false);
	const [stripeActionResultMessage, setStripeActionResultMessage] = useState<string | undefined>(undefined);
	const [errorString, setErrorString] = useState<string | undefined>(undefined);
	const [savedCardList, setSavedCardList] = useState<CardDetailType[]>([]);
	const [paymentLoadingScreen, setPaymentLoadingScreen] = useState<boolean>(false);

	const [isChangeCard, setIsChangeCard] = useState<boolean>(false)
	const [newStripeCardSave, setNewStripeCard] = useState<CardDataTypes>({
		expiryDate: '',
		cardNumber: '',
		cardHolderName: '',
		cvv: ''
	})
	
	const [isAddCardForm, setIsCardForm] = useState<boolean>(false)
	const [incorrectCardNumber, setIncorrectCardNumber] = useState<boolean>(false)
	const [cardErrorMessage, setCardErrorMessage] = useState<string>('');
	const [cardSuccessMessage, setCardSuccessMessage] = useState<boolean>(false)
	// Customizable Area End

	// Customizable Area Start
	const { sendBlockMessage, sendNetworkRequest, setReceiveCallback, subscribe, unsubscribeFromMessage } =
		useRunEngine();


	const [stripePromise] = useState(loadStripe(saveStripeKay));

	React.useEffect(() => {
		if(cardSuccessMessage){
			setTimeout(() => {
				setCardSuccessMessage(false);
			}, 1500);
		}
	}, [cardSuccessMessage])

	const restoreSessionFromLocalStorage = () => {
		const persistedAuthToken = getStorageData('authToken',false)
		if (persistedAuthToken) {
			const messsage: Message = new Message(getName(MessageEnum.SessionSaveMessage));
			messsage.addData(getName(MessageEnum.SessionResponseToken), persistedAuthToken);
			sendBlockMessage(messsage);
		}
	};
	const getToken = () => {
		const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
		sendBlockMessage(message);
	};
	// Customizable Area End

	const receive = (from: string, message: Message) => {
		// Customizable Area Start
		if (getName(MessageEnum.SessionResponseMessage) === message.id) {
			let resToken = message.getData(getName(MessageEnum.SessionResponseToken));
			if (resToken) {
				setUserAppAuthenticationToken(resToken);
			} else {
				restoreSessionFromLocalStorage();
			}
		} else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			handleResponseData(from, message);
		}
		// Customizable Area End
	};

	// Customizable Area Start
	const handleResponseData = (from: string, message: Message) => {
		const { apiRequestCallId, responseJson } = extractNetworkResponse(message);
		if (apiRequestCallId === getPaymentMethodsCallId.current || from === 'UNIT_TEST') {
			setPaymentIntentId(
				responseJson.payment_intent.id
			)
			setStripeClientSecret(responseJson.payment_intent.client_secret);
		} else if (apiRequestCallId === getSavedCardListId.current || from === 'UNIT_TEST_CARD_LIST') {
			setSavedCardList(responseJson.data);
			setCardDataDetails(responseJson.data[0])
		} else if (apiRequestCallId === saveNewCardId.current || from === 'ADD_CARD_CALLBACK') {
			if(responseJson.id) {
				updateCardToServer(responseJson);
			} else if(responseJson.error) {
				setCardErrorMessage(responseJson.error.message)
			}
		} else if (apiRequestCallId === saveCardToServerId.current || from === 'SAVE_CARD_TO_SERVER') {
			if(responseJson.errors?.length) {
				setCardSuccessMessage(false);
				setCardErrorMessage(responseJson.errors[0].stripe)
				setIsCardForm(true)
			} else {
				setIsCardForm(false)
				setCardSuccessMessage(true);
				getSavedCardList();
			}
		} else if (apiRequestCallId === getPaymentSuccessMethodsCallId.current || from === 'CHECK_SUCCESS_PAYMENT') {
			setPaymentLoadingScreen(false);
			if(responseJson.message === 'Payment Success') {
				navigation.handlePostCreation();
			}
			else {
				handleMessage('PaymentFailed')
			}
		}
	}
	const updateCardToServer = async (responseJson: IInterFace) => {
		sendNetworkRequest(
			saveCardToServerId,
			configJSON.apiMethodType,
			configJSON.SavedAccountEndPoint,
			{
				'Content-Type': configJSON.apiContentType,
				token: token,
			},
			{
				payment_method: {
					"name": newStripeCardSave.cardHolderName,
					"token": responseJson.id,
				}
			}
		);
	}

	const getSavedCardList = async () => {
		sendNetworkRequest(
			getSavedCardListId,
			configJSON.getPaymentMethod,
			configJSON.SavedAccountEndPoint,
			{
				'Content-Type': configJSON.apiContentType,
				token: token,
			}
		);
	};

	const createPaymentIntent = async () => {

		sendNetworkRequest(
			getPaymentMethodsCallId,
			config.createPaymentIntentMethod,
			config.PaymentIntentEndPoint,
			{
				'Content-Type': config.creatPaymentIntentApiContentType,
				token: token,
			},
			{
				"payment": {
					"screen_id": uploadScreenData.screenSelected.id,
				}
			}
		);
	};

	// Customizable Area End

	useEffect(() => {
		setReceiveCallback(receive);
		subscribedMessages.forEach((message) => subscribe(message));
		// Customizable Area Start
		getToken();
		// Customizable Area End
		return () => {
			subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
		};
	}, []);

	// Customizable Area Start
	useEffect(() => {
		if (uploadScreenData.screenSelected.id && isPaymentDrawer) {
			createPaymentIntent();
		}
	}, [uploadScreenData.screenSelected.id]);
	useEffect(() => {
		getSavedCardList()
	}, []);
	// Customizable Area End

	// Customizable Area Start
	const handleCardSubmit = (values: IHandleAddFormData) => {
		setNewStripeCard(values.cardData)
		const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
		saveNewCardId.current = requestMessage.messageId;
	
		requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify({'Content-Type': configJSON.apiEncodedContentPath, 'Authorization': `Bearer ${saveStripeKay}`}));
		requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.postStripeToken);
		requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.apiStripeToken);

		requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), values.formData);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	const onHandleSubmit = async (event: React.MouseEvent<HTMLElement>, stripe: Stripe | null, stripeElements: StripeElements | null) => {
		event.preventDefault();
		setPaymentLoadingScreen(true);
		if (!stripe || !stripeElements || !stripeClientSecret) return;
		const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(stripeClientSecret, { payment_method: cardDataDetails.id });

		if (stripeError) {
			setPaymentLoadingScreen(false); handleMessage('PaymentFailed')
			setErrorString(stripeError.message || 'Payment failed'); return;
		}
		if (paymentIntent?.status === 'requires_action') {
			setPaymentLoadingScreen(false);
			const { error: actionError } = await stripe.handleCardAction(stripeClientSecret);
			if (actionError) {
			  setErrorString(actionError.message || '3D Secure authentication failed');
			  handleMessage('PaymentFailed');
			  return;
			}
		}
		handlePaymentSuccess();
	};
	const handleIsChangeCard = () => {
		setIsChangeCard((prev: boolean) => !prev)
	}

	const handlePaymentSuccess = () => {
		sendNetworkRequest(getPaymentSuccessMethodsCallId, config.createPaymentIntentMethod, configJSON.PaymentSuccessEndPoint, {'Content-Type': config.creatPaymentIntentApiContentType,token: token,}, {"payment": {"payment_intent_id": paymentIntentId,"screen_id": uploadScreenData.screenSelected.id, "amount": uploadScreenData.amount}});
	}

	const getCardNumber = React.useCallback((): string => {
		return `**** **** **** ${cardDataDetails.attributes?.card.last4 || '****'}`;
	}, [cardDataDetails]);
		
	const getCardIconSrc = (selectedCardDataDetails: CardDetailType): string => {    
		if (selectedCardDataDetails.attributes?.card.brand === 'mastercard') {
		  return master_card_icon;
		} else if (selectedCardDataDetails.attributes?.card.brand === 'visa') {
		  return visa_dark_card_icon;
		} else {
		  return "";
		}
	};

	const getTotalDays = () => {
		return moment(new Date(uploadScreenData.endDate)).diff(moment(new Date(uploadScreenData.startDate)), 'days') + 1
	}
	
	
	const handleCardItemFromList = (data: CardDetailType) => {
		setCardDataDetails(data);
		setIsChangeCard(false);
	  }
	// Customizable Area End

	// Customizable Area Start
	const submitOrderNumberButtonProps = {
		value: config.submitText,
	};
	const loadingViewProps = {
		value: config.loading,
	};

	const submitPaymentButtonProps = {
		value: config.submitText,
	};

	const stripeMessageViewProps = {
		successValue: config.stripeSuccessMessage,
		errorValue: config.stripeErrorMessage,
	};
	// Customizable Area End

	const viewProps: ViewProps = {
		testID: id,
		// Customizable Area Start
		errorString,
		stripePromise: stripePromise,
		orderNumber: orderNumber,
		actionResult: stripeActionResultMessage,
		stripeClientSecret: stripeClientSecret,
		stripeInitialised: stripeInitialised,
		isInStripeCallback: isInStripeCallback,
		loadingViewProps: loadingViewProps,
		submitOrderNumberButtonViewProps: submitOrderNumberButtonProps,
		submitPaymentButtonViewProps: submitPaymentButtonProps,
		
		orderIdViewProps: {
			isAddCardForm,
			cardSuccessMessage,
			incorrectCardNumber,
			paymentLoadingScreen,
			cardErrorMessage,
			isChangeCard:isChangeCard,
			savedStripCardList: savedCardList,
			handlePaymentDetailDrawer: handlePaymentDrawer,
			selectedCardDataDetails:cardDataDetails,
			uploadedScreenDetails: uploadScreenData,
			stripCardDetailList: cardDetails,
			getTotalDays,
			setIsCardForm,
			getCardNumber: getCardNumber,
			handleCardItemFromList: handleCardItemFromList,
			handleCardSubmit: handleCardSubmit,
			getCardIconSrc: getCardIconSrc,
			handleIsChangeCard: handleIsChangeCard,
			...navigation,
		},
		setOrderNumber: setOrderNumber,
		onHandleSubmit: onHandleSubmit,
		stripeMessageViewProps: stripeMessageViewProps,
		
		// Customizable Area End
	};

	return <StripePaymentsView {...viewProps} />;
};

export default StripePayments;
