import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  FormControlLabel,
  Drawer,
  Collapse,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { IOSSwitch } from '../../utilities/src/customComponents'
import { CheckCircle, ChevronRight, Close, Error, } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        height: '1.2px'
      }
    },
    MuiDrawer: {
      root: {
        '&.confirmationAlert div:nth-child(3)': {
          background: "#000",
          borderTopRightRadius: "24px"
        },

      }

    },
    MuiButton: {
      root: {
        fontSize: '1em',
        textTransform: 'none',
        borderRadius: '8px',
        margin: '12 0 0',
        fontWeight: 700,
        '&..Component-switchBase-3 + .Component-track-5' : {
          background: "#334155 !important" 
        },
        '&.conatinedBtn': {
          color: '#000',
          background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%);',
          padding: '12',
          marginTop: '12px',
          marginBottom: '16px'
        },
        '&.textBtn': {
          color: "#fff",
          marginTop: '24px'
        }
      }
    },
    MuiCollapse: {
      root: {
        '&.alertCollapse': {
          position: "fixed",
          bottom: '16%',
          left: '3%',
          width: '94%',
          display: 'flex',
          justifyContent: 'center'
        },
        '&.alertCollapse > div > div > div': {
          backgroundColor: '#fff',
          alignItems: "center",
          borderRadius: '8px',
          padding: '9px 16px',
          boxShadow: '0px 6px 15px -3px #00000026',
          fontSize: '16px',
          color: '#0F172A'
        }
      }
    },
    MuiContainer: {
      root: {
        '&.MuiContainer-root-1': {
          paddingLeft: '0px !important',
          paddingRight: '0px !important'
        }
      }
    }
  }
});
// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController";
import BottomNavigationBar from "../../hamburgermenu/src/HamburgerMenu.web";

export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMenu = (listToRender: any) => {
    return (
      <>
      <Divider style={{ height: '1.2px' ,backgroundColor:'#334155'}} />
     { listToRender?.map((item: any, index: number) => {
      return (
        <>
          <ListItem key={`${item.title}${index}`} button data-test-id={`${item.title}btn`} onClick={() => this.gotoPage(item.path, item.title)} style={{ padding: '12px 24px', }}>
            <ListItemIcon key={`icon${item.title}${index}`} style={{ minWidth: '30px' }}>
              <img alt={item.title} src={this.getIconForMenu(item.icon)} style={{padding: item.title == "Card details" || item.title == "Personal details" ? "4px" : item.title == "Currency" || item.title == "Change password" ? "0 4px" : 0, height: '24px', width: '24px' ,color:'#CBD5E1'}} />
            </ListItemIcon>
            <ListItemText key={`text${item.title}${index}`} style={{ fontSize: '1em', fontWeight: 400, color:'#CBD5E1'}} >
              <span style={{ padding: '0', color: "#CBD5E1" }}>{item.title}</span><br />
              <span style={{ padding: '0', color: '#94A3B8', fontSize: '0.9em', fontWeight: 400 }}>{item.subHeader}</span>
            </ListItemText>
            {item.rightIcon ? <ListItemIcon key={`${item.title}${index}icon`} style={{ minWidth: '10px' }}><ChevronRight style={{color: "#CBD5E1"}}/></ListItemIcon> : null}

          </ListItem>
        </>)
        
    })}</>)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BottomNavigationBar navigation={this.props.navigation} fromPage="settings">
        <ThemeProvider theme={theme}>
        <div style={{ height: 'calc(100% - 128px)', overflowY: 'hidden', position: 'absolute', width: '100%', top: '60px'}}>

        <Typography style={{ fontSize: '30px', fontWeight: 700, padding: '0 0 20px 24px', color: "#fff", backgroundColor: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)', width: '100%', zIndex: '99' }}>Settings</Typography>
              <Divider style={{ height: '1.2px',backgroundColor:'#64748B'}} />
              <Container maxWidth={"sm"} style={{ padding: 0, height: 'calc(100% + 133px)', position: 'relative', overflowY: "auto", paddingBottom: "195px" }}>
            <Box sx={webStyle.mainWrapper}>
              <div style={{ marginTop: '15px', height: "calc(100vh - 205px)", overflowY: "auto" }}>
                <ListSubheader inset style={{ color: "#F1F5F9", padding: '0px 24px 0px 24px', position: "relative", fontSize: '14px', fontWeight: 700, textTransform: 'uppercase', zIndex: 0 }}>Account</ListSubheader>
                {this.renderMenu(this.state.accountsList)}

                <ListSubheader inset style={{ position: "relative", padding: '0 24px', fontSize: '14px', fontWeight: 700, textTransform: 'uppercase', marginTop: '24px', zIndex: 0,color:"#F1F5F9" }}>Order details</ListSubheader>
                {this.renderMenu(this.state.orderList)}

                <ListSubheader inset style={{ padding: '0 24px', fontSize: '14px', fontWeight: 700, textTransform: 'uppercase', marginTop: '24px', zIndex: 0 ,color:"#F1F5F9"}}>Account actions</ListSubheader>
                <Divider style={{ height: '1.2px' }} />
                {this.state.accountActionList.map((item: any, index: number) => {
                  return (
                    <>
                      <ListItem key={`${item.title}${index}`} button data-test-id={`${item.actionToTake}btn`} style={{ padding: '8 -1', paddingLeft: '24px', paddingRight: '24px'}} onClick={() => this.manageAlertPopUp(item.actionToTake)}>
                        <ListItemIcon key={`icon${item.title}${index}`} style={{ minWidth: '30px' }}>
                          <img alt={item.title} src={this.getIconForMenu(item.icon)} style={{ height: '24px', width: '24px', padding: "0 4px",color:'#DC2626'}} />
                        </ListItemIcon>
                        <ListItemText key={`text${item.title}${index}`} style={{ fontSize: '1em', fontWeight: 400, color: '#0F172A' }} >
                          <span style={{ padding: '0', color: item.actionToTake == 'delete' ? '#DC2626' : '#E0E0E0  ' }}>{item.title}</span><br />
                        </ListItemText>
                      </ListItem>
                    </>)
                })}
              </div>
              <Collapse className="alertCollapse" data-test-id="alert-notification" in={this.state.showErrMsg} >
                <Alert icon={this.state.errMessage.type == 'error' ? <Error style={{ color: 'red' }} fontSize="inherit" /> : <CheckCircle style={{ color: '#34D399' }} fontSize="inherit" />} severity={this.state.errMessage.type == 'error' ? "error" : 'success'}  >
                  {this.state.errMessage.msg}
                </Alert>
              </Collapse>
            </Box>

            <Drawer data-test-id="confirmation-alert" className="confirmationAlert" anchor={'bottom'} open={this.state.showAlert} onClose={() => this.closeAlertPopUp()} >
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button variant="text" data-test-id="closeBtn" style={{ margin: '20px 3px', padding: '0px' }} onClick={() => this.closeAlertPopUp()}><Close style={{ fontSize: '2em', fill: "#fff" }} /></Button>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>

                <Typography component="div" style={{ fontSize: '18px', fontWeight: 700, margin: '12 0 0', color: '#fff' }}>
                  {this.state.alertData.title}
                </Typography>
                <Typography component="div" style={{ fontSize: '16px', fontWeight: 400, margin: '8 0', color: '#fff' }}>
                  {this.state.alertData.text}
                </Typography>
                {this.state.alertData.buttons.map((button: { action: string, name: string, type: "text" | "outlined" | "contained" | undefined, class: string }, index: number) => {
                  return (
                    <Button
                      key={button.action}
                      data-test-id={`${button.action}Btn`}
                      className={button.class}
                      fullWidth
                      variant={button.type}
                      onClick={() => this.performActionOnAccount(button.action)}
                    >
                      {button.name}
                    </Button>
                  )
                })}
              </Box>
            </Drawer>
          </Container>
          </div>
        </ThemeProvider>
      </BottomNavigationBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "42px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
