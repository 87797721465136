import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import React from "react";
import { createConsumer } from "@rails/actioncable";

const baseURL = require("../../../framework/src/config");
let consumer: any = null;

const getCable = (): any => {
  if (!consumer) {
    const url = 'wss' + baseURL.baseURL.replace("https", "") + "/cable"

    consumer = createConsumer(url);
  }
  return consumer;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  current: number;
  screenAssetsData: any;
  intervalId: any;
  assetType: string;
  qrCode: string;
  muted: boolean;
  loader: boolean;
  error: string;
  randomKey: number;
  showButton: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfonplatformscreencreation0Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  screenAssetsApiCallId: string = "";
  videoRef: any;
  socket: any;
  subscription: any;
  interval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      current: 0,
      screenAssetsData: [],
      intervalId: "",
      assetType: "",
      qrCode: "",
      muted: true,
      loader: true,
      error: "",
      randomKey: 0,
      showButton: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.videoRef = [];
    this.subscription = "";
    this.setupSocket = this.setupSocket.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.screenAssetsApiCallId) {
          this.setState({ loader: false });
          if (!responseJson.errors) {
            this.handleCurrentScreenAssets(responseJson);
          } else {
            if (responseJson.errors) {
              this.setState({ error: responseJson.errors, qrCode: "", assetType: "", screenAssetsData: [] });
            } else {
              this.setState({ error: "Something went wrong!", qrCode: "", assetType: "", screenAssetsData: [] });
            }
          }
        }
      }
    }
    // Customizable Area End
  }


  handleCurrentScreenAssets = (responseJson: any) => {
    if (responseJson.data.qr_code) {
      this.setState({ qrCode: responseJson.data.qr_code, screenAssetsData: "", assetType: "", error: "" })
    } else if (responseJson.data.assets.length) {
      const assets = responseJson.data.assets.map((item: any) => item.url);
      if (this.state.screenAssetsData.length > 0) {
        this.setState({ screenAssetsData: assets });
      } else {
        if (responseJson.data.assets[0].media_type.includes("image")) {
          this.setState({ screenAssetsData: assets, assetType: responseJson.data.assets[0].media_type, current: 0, qrCode: "", error: "" }, this.startSlideshow);
        } else {
          this.setState({ screenAssetsData: assets, assetType: responseJson.data.assets[0].media_type, current: 0, qrCode: "", error: "" }, this.handleVideoPlayback);
        }
      }
    }
  }

  startSlideshow = () => {
    const intervalId = setInterval(() => {
      this.setState(prevState => ({
        current: (prevState.current + 1) % prevState.screenAssetsData.length // Move to the next image, loop back
      }));
    }, 5000); // 5 seconds interval

    this.setState({ intervalId });
  };

  setupSocket() {
    const cable = getCable();
    const getScreen = () => {
      this.setState({ randomKey: Math.random() });
    }

    this.subscription = cable.subscriptions.create(
      { channel: "ScreenStatusChannel" },
      {
        connected() {
          console.log("Connected to the chat room");
        },
        received(data: any) {
          console.log("daata room", data);
          if (data.status === "active" || data.status === "published") {
            getScreen();
          }
        },
        consumer() {
          console.log("daata room");
        },
        disconnected() {
          console.log("Disconnected from the chat room");
        },
      }
    );
  }

  async componentDidMount(): Promise<void> {
    this.getScreenAssets();
    this.setupSocket();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.randomKey !== prevState.randomKey) {
      this.getScreenAssets();
    }
  };

  async componentWillUnmount(): Promise<void> {
    this.subscription.unsubscribe();
    if (this.state.intervalId) {
      clearInterval(this.interval);
    }
  }

  handleVideoEnd = () => {
    this.setState(prevState => ({
      current: (prevState.current + 1) % prevState.screenAssetsData.length // Move to the next image, loop back
    }), this.handleVideoPlayback);
  }

  handleUnmute = () => {
    this.setState({ muted: false }, () => {
      const video = this.videoRef[this.state.current];
      if (video) {
        video.muted = false;
      }
    });

    setTimeout(() => {
      this.setState({ showButton: false });
    }, 2000);
  }

  handleVideoPlayback = () => {
    this.videoRef.forEach((video: any, index: any) => {
      if (video) {
        if (index === this.state.current) {
          video.play(); // Play the active video

        } else {
          video.pause(); // Pause all other videos
          video.currentTime = 0; // Reset to the beginning for next time
        }
      }
    });
  };

  getScreenAssets() {
    this.setState({ loader: true })
    const screenId = this.props.navigation.getParam('uniqueid');
    const headers = {
      "Content-Type": configJSON.screenAssetsApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.screenAssetsApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.screenAssetsAPiEndPoint + "3c92b59c45ea366a3f71342"
      // configJSON.screenAssetsAPiEndPoint + "7fbd54e706a273a3253b341"
      configJSON.screenAssetsAPiEndPoint + screenId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.screenAssetsApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  // web events

  // Customizable Area Start
  // Customizable Area End
}
