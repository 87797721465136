import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { imgVisbility, imgVisbilityOff } from "./assets";

// Customizable Area End

import Cfonplatformscreencreation0Controller, {
  Props,
  configJSON,
} from "./Cfonplatformscreencreation0Controller";
import { Box, CircularProgress } from "@material-ui/core";

export default class Cfonplatformscreencreation0 extends Cfonplatformscreencreation0Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ width: "100%", height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: "#000" }}>
        {!this.state.loader && this.state.qrCode ?
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}>
            <img src={require('../assets/Image_logo_white.png')} style={{ height: '40vh', marginTop: "40px" }} />
            <img data-test-id="qr-block" src={this.state.qrCode} style={{ height: '40vh', marginBottom: "40px" }} alt="ads" />
          </div>
          : ""}
        {this.state.loader && <div style={styles.circularProgressDiv}>
          <CircularProgress style={{ color: 'grey' }} />
        </div>}
        {this.state.assetType !== "" &&
          <div style={{
            display: 'block',
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            aspectRatio: '1/1',
            maxWidth: '100vw',
            height: "100%",
            overflow: 'hidden',
          }}
            data-test-id="fade-element"
          >
            {this.state.assetType.includes("image") ?
              this.state.screenAssetsData.map((item: any, index: any) => {
                return <img data-test-id={`img-block-${index}`} key={item} className={`slide ${index === this.state.current ? 'active' : ''}`} src={item} style={{ objectFit: "contain", display: 'block', width: '100%', height: '100%' }} alt="ads" />
              })
              : ""}
            {this.state.assetType.includes("video") && this.state.screenAssetsData.length > 1 ?
              this.state.screenAssetsData.map((item: any, index: any) => {
                return <video
                  data-test-id={`multi-video-block-${index}`}
                  key={item} 
                  id="myVideo"
                  muted={this.state.muted}
                  src={item}
                  className={`slide ${index === this.state.current ? 'active' : ''}`}
                  style={{ objectFit: "contain", display: 'block', width: '100%', height: '100%', pointerEvents: "none" }}
                  autoPlay={false}
                  loop={false}
                  onEnded={this.handleVideoEnd}
                  ref={el => this.videoRef[index] = el} 
                ></video>
              })
              : ""}
            {this.state.assetType.includes("video") && this.state.screenAssetsData.length === 1 ?
              <video
                src={this.state.screenAssetsData[0]}
                data-test-id="video-block"
                id="myVideo"
                style={{ objectFit: "contain", display: 'block', width: '100%', height: '100%', pointerEvents: "none" }}
                autoPlay
                loop
                muted={this.state.muted}
                ref={el => this.videoRef[0] = el} 
              ></video>
              : ""}

          </div>}
        {this.state.assetType.includes("video") && this.state.showButton && <button data-test-id="unmute-btn" style={styles.unmuteBtn} onClick={() => this.handleUnmute()}>Unmute</button>}
        {this.state.error && <Box position={"fixed"} style={styles.errorWrapper}>
          <div data-test-id="error-block" style={{ ...styles.errorText, padding: "5px 15px" }}>{this.state.error}</div>
        </Box>}
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",


  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
  circularProgressDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  errorWrapper: {
    bottom: "20px",
    color: "red",
    left: "0",
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  errorText: {
    backgroundColor: "rgb(254, 226, 226)",
    borderRadius: 5,
  },
  unmuteBtn: {
    position: 'absolute',
    bottom: 20,
    right: 25,
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: 6,
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
  }
});


// Customizable Area End
