Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "uploadmedia3";
exports.labelBodyText = "uploadmedia3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.uploadMediaApiEndPoint='/bx_block_custom_form/screens/upload_media'
exports.SavedAccountEndPoint ='/bx_block_stripe_integration/payment_methods';
exports.getScreensApiEndPoint = 'bx_block_custom_form/screens/'
exports.getScreenFilesApiEndPoint = 'bx_block_custom_form/screens/screen_files'
exports.getScreensBookingContentType = "application/json";
exports.getScreensBookingMathodType = "GET";
exports.getScreenBooking = 'bx_block_custom_form/bookings';
exports.postCreationAPiMethod = "POST";
exports.postCreationApiEndPoint = 'bx_block_custom_form/screens/bulk_upload';
exports.notificationsDataApiEndPoint = '/account_block/accounts/specific_account';
exports.ScreenStatusData = ["active", "available", "unavailable"]
exports.ScreenFileTypeData = ["Video", "Image"]
// Customizable Area End