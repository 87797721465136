import React from "react";
import { AppBar, Toolbar, Box, Button, Typography, styled, InputAdornment, TextField, OutlinedInput, Popover, Divider, Drawer, TableContainer, List, ListItem, ListItemText, Grid, createTheme, ThemeProvider, Collapse } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { FaSearch } from "react-icons/fa";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ScreenTable from '../../../components/src/commonTable/ScreenList';
import { MdEventAvailable, MdOutlineFileUpload, MdOutlineDvr, MdClear } from "react-icons/md";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Alert } from "@material-ui/lab";
import HamburgerMenu from "../../../../packages/blocks/hamburgermenu/src/HamburgerMenu.web";
import LocalListingAdsController from "../../../../packages/blocks/locallistingads/src/LocalListingAdsController";
import UsersTable from "../commonTable/UsersList";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { getToken } from "../../../../packages/blocks/utilities/src/RegExRequired";
import { GenericApiCall } from "../../../../packages/blocks/utilities/src/GenericApiCall";
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { get } from "../commonTable/table";
import { getStorageData } from "../../../framework/src/Utilities";
import { CheckCircle, Close, Error } from "@material-ui/icons";

const config = require("../../../../packages/blocks/locallistingads/src/config.js");
export const configJSON = require("../config");

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiDivider: {
            root: {
                height: '1.2px'
            }
        },
        MuiDrawer: {
            root: {
                '&.confirmationAlert div:nth-child(3)': {
                    background: "#000",
                    borderTopRightRadius: "24px"
                },

            }

        },
        MuiButton: {
            root: {
                fontSize: '1em',
                textTransform: 'none',
                borderRadius: '8px',
                margin: '12 0 0',
                fontWeight: 700,
                '&..Component-switchBase-3 + .Component-track-5': {
                    background: "#334155 !important"
                },
                '&.conatinedBtn': {
                    color: '#000',
                    background: 'linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%);',
                    padding: '12',
                    marginTop: '12px',
                    marginBottom: '16px'
                },
                '&.textBtn': {
                    color: "#fff",
                    marginTop: '24px'
                }
            }
        },
        MuiCollapse: {
            root: {
                '&.alertCollapse': {
                    position: "fixed",
                    bottom: '16%',
                    left: '3%',
                    width: '94%',
                    display: 'flex',
                    justifyContent: 'center'
                },
                '&.alertCollapse > div > div > div': {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    padding: '8px 8px',
                    boxShadow: '0px 4px 7px grey',
                    fontSize: '0.9em',
                    color: '#0F172A'
                }
            }
        },
        MuiContainer: {
            root: {
                '&.MuiContainer-root-1': {
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important'
                }
            }
        }
    }
});

const headerList = [
    {
        title: "User Name",
        key: "name",
        dataKey: "name"
    },
    {
        title: "Date Joined",
        key: "date_joined",
        dataKey: "date_joined"
    },
    {
        title: "Status",
        key: "status",
        dataKey: "status"
    },
    {
        title: "",
        key: "action",
        dataKey: ""
    },
];

type StringOrNumber = string | number | null;
type recentScreenListType = { [key: string]: string }
type objectDataType = { [key: string]: string | { [key: string]: StringOrNumber } }
interface S {
    screenList: any;
    isSearch: boolean;
    screenSearch: string;
    anchorEl: null | HTMLElement;
    screenDetail: boolean;
    fileDetailData: object;
    selectedButton: string;
    displayAlert: boolean;
    screenLoader: boolean;
    filterScreenBy: string;
    showAlert: boolean;
    currentRowData: any;
    isAlphabatical: boolean;
    isDateJoined: boolean;
    errMessage: {
        type: "error" | "warning" | "info" | "success" | "",
        msg: string
      },
    showErrMsg: boolean;
}
export interface Props {
    navigation: any;
    id: string;
    handlePageState?: (data: string) => void;
    handleMessage?: (message: string) => void;
}

interface IStyleTypes {
    availableAlert: objectDataType;
    unavailableAlert: objectDataType;
    activeAlert: objectDataType;
}

interface SS {
    navigation: any;
}

export default class ManagerUsers extends BlockComponent<Props, S, SS> {
    getUserProfileApiCallId: string = '';
    updateUserProfileApiCallId: string = '';
    regionScreensApiID: string = '';
    blockUserApiCallId: string = '';
    viewAddDetailsApiCallId: string = '';
    viewUserDetailsApiCallId: string = '';
    errorMsgInterval: any;
    constructor(props: Props) {

        super(props);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        this.state = {
            screenList: [],
            isSearch: false,
            screenSearch: "",
            anchorEl: null,
            screenDetail: false,
            fileDetailData: {},
            selectedButton: "",
            displayAlert: false,
            screenLoader: false,
            filterScreenBy: "all",
            showAlert: false,
            currentRowData: {},
            isAlphabatical: false,
            isDateJoined: false,
            errMessage: {
                type: '',
                msg: ''
              },
            showErrMsg: false,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            if (responseJson) {
                if (responseJson.error) {
                    this.setState({
                        screenLoader: false
                    })
                    this.setState({
                        errMessage: { type: 'error', msg: responseJson.error },
                        showErrMsg: true
                      });
              
                      if (this.errorMsgInterval) {
                        clearInterval(this.errorMsgInterval);
                      }
                  
                      this.errorMsgInterval = setTimeout(() => {
                        this.setState({ showErrMsg: false });
                      }, 5000);
                }
                else {
                    switch (apiRequestCallId) {
                        case this.updateUserProfileApiCallId:
                            this.setState({
                                screenLoader: false
                            })
                            break;
                        case this.getUserProfileApiCallId:
                            let fetchedData: any = responseJson.users || []
                            if (this.state.filterScreenBy == "active") {
                                fetchedData = fetchedData.filter((data: any) => data.status === "active");
                            } else if (this.state.filterScreenBy == "inactive") {
                                fetchedData = fetchedData.filter((data: any) => data.status === "inactive");
                            } else if (this.state.filterScreenBy == "alphabetical") {
                                if (this.state.isAlphabatical == true) {
                                    fetchedData = fetchedData.sort((a: any, b: any) => {
                                        return a.name.localeCompare(b.name);
                                    });
                                } else {
                                    fetchedData = fetchedData.sort((a: any, b: any) => {
                                        if (a.name < b.name) {
                                            return 1;
                                        }
                                        if (a.name > b.name) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (this.state.filterScreenBy == "dateJoined") {
                                if (this.state.isDateJoined == true) {
                                    fetchedData = fetchedData.sort((a: any, b: any) => {
                                        const dateA: any = new Date(a.date_joined.split('/').reverse().join('-')).getTime();
                                        const dateB: any = new Date(b.date_joined.split('/').reverse().join('-')).getTime();
                                        return dateA - dateB;
                                    });
                                } else {
                                    fetchedData = fetchedData.sort((a: any, b: any) => {
                                        const dateA = this.parseDate(a.date_joined);
                                        const dateB = this.parseDate(b.date_joined);
                                        return dateB.getTime() - dateA.getTime();
                                    });
                                }
                            } else {
                                fetchedData = responseJson.users || []
                            }
                            this.setState({
                                screenList: fetchedData || [],
                                screenLoader: false
                            })
                        case this.blockUserApiCallId:
                            this.setState({
                                screenLoader: false
                            })
                            if (responseJson.message) {
                                this.fetchUsers()
                            this.setState({
                                errMessage: { type: 'success', msg: responseJson.message },
                                showErrMsg: true
                              });
                      
                              if (this.errorMsgInterval) {
                                clearInterval(this.errorMsgInterval);
                              }
                          
                              this.errorMsgInterval = setTimeout(() => {
                                this.setState({ showErrMsg: false });
                              }, 5000);
                            }
                            break;

                        default:
                            this.setState({
                                screenLoader: false
                            })

                            break;
                    }
                    this.setState({ showAlert: false })
                }
                this.setState({ showAlert: false })
            }
        }
    }

    parseDate(dateString: string): Date {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    }

    async componentDidMount() {
        let token = getToken();
        if (!token) {
            this.props.navigation.navigate('EmailAccountLoginBlock')
        } else {
            this.fetchUsers()
        }
    }

    fetchUsers = () => {
        this.setState({
            screenLoader: true
        })
        let token = getToken();
        let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.httpGetMethod, configJSON.getUsersApiEndPoint, token || '')
        this.getUserProfileApiCallId = requestMesg.messageId;
        runEngine.sendMessage(requestMesg.id, requestMesg)
    }

    apiCall = async (data: {
        contentType: string;
        method: string;
        endPoint: string;
        bodyData?: { [key: string]: string | number }
    }) => {
        const { contentType, method, endPoint, bodyData } = data;
        const token = await getStorageData("token");
        const header = {
            "Content-Type": contentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        bodyData && requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(bodyData)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    handleHeaderFilter = async (dataKey: string) => {
        this.setState({ filterScreenBy: dataKey })
        this.setState({ screenLoader: true });
        this.fetchUsers()
    }

    renderHeader = () => {
        return (
            <StyledHeader>
                {!this.state.isSearch && (
                    <Typography className="pageTitle" style={{ marginLeft: "auto" }}>
                        {"USERS"}
                    </Typography>
                )}
                {this.state.isSearch ? (
                    <Typography data-test-id="search_data" className="pageTitle" style={{ marginLeft: "auto" }}
                    >
                        {config.headerSearchTxt}
                    </Typography>
                ) : (
                    <FaSearch data-test-id="searchIcon" style={{ marginLeft: "auto" }}
                    onClick={() => this.props.navigation.navigate('ManagerSearch')} 
                    />
                )}
            </StyledHeader>
        );
    };

    renderTopActions = () => {
        return (
            <>
                <TopActions>
                    {this.state.isSearch && (
                        <StyledTextField
                            data-test-id="search_screens"
                            variant="outlined"
                            value={this.state.screenSearch}
                            placeholder={config.searchScreensPlaceholderTxt}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {this.state.screenSearch && (
                                            <MdClear data-test-id="clear_input" className="clearIcon"
                                            />
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    {this.state.isSearch && (
                        <RightAlignedTypography data-test-id="clear_data" className="pageTitle"
                        >
                            {"Clear"}
                        </RightAlignedTypography>
                    )
                    }
                    <StyledPopover
                        data-test-id="popover-data"
                        elevation={3}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                    >
                        <Box className="formControl">
                            <StyledTextField
                                variant="outlined"
                                placeholder={config.headerSearchTxt}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon className="searchIcon" /></InputAdornment>,
                                }}
                            />
                        </Box>
                    </StyledPopover>
                </TopActions>
            </>
        );
    };

    navigateToPage = (path: string, params: any) => {
        this.props.navigation.navigate(path, params)
    }

    renderScreens = () => {
        return (
            <>
                <RenderScreens>
                    <TableBox>
                        <UpdatedTableContainer >
                            <UsersTable
                                data-test-id="table"
                                headerKeys={headerList}
                                tableData={this.state.screenList}
                                showFilter={true}
                                moreMenuList={[]}
                                isLoading={this.state.screenLoader}
                                handleBlock={() => {
                                    this.setState({ showAlert: true })
                                }}
                                handleSetCurrentRowData={(row: any) => {
                                    this.setState({ currentRowData: row })
                                }}
                                handleSetIsAlphabatical={() => this.setState({ isAlphabatical: this.state.isAlphabatical == true ? false : true })}
                                handleSetIsDateJoined={() => this.setState({ isDateJoined: this.state.isDateJoined == true ? false : true })}
                                handleHeaderFilter={this.handleHeaderFilter}
                                navigation={this.props.navigation}
                                state={this.state}
                            />
                        </UpdatedTableContainer>
                    </TableBox>
                </RenderScreens>
            </>
        );
    };

    getFieldValue = (keyValue: string): string => {
        return get(this.state.fileDetailData, `attributes.${keyValue}`)
    }

    closeAlertPopUp = () => {
        this.setState({ showAlert: false })
    }

    performActionOnBlock = () => {
        let token = JSON.parse(JSON.stringify(localStorage.getItem('token')));

        let requestMesg = GenericApiCall(configJSON.validationApiContentType, configJSON.httpPutMethod, this.state.currentRowData.status == 'blocked' ? configJSON.unblockUserApiEndPoint : configJSON.blockUserApiEndPoint, token,  JSON.stringify({id : this.state.currentRowData.id}))
        this.blockUserApiCallId = requestMesg.messageId;
        runEngine.sendMessage(requestMesg.id, requestMesg)
    }

    render() {

        return (
            <HamburgerMenu
                navigation={this.props.navigation}
                fromPage="user"
                hideBottom={!!this.state.isSearch}
            >
                <ThemeProvider theme={theme}>
                    <Collapse className="alertCollapse" data-test-id="alert-notification" in={this.state.showErrMsg} >
                        <Alert icon={this.state.errMessage.type == 'error' ? <Error style={{ color: 'red' }} fontSize="inherit" /> : <CheckCircle style={{ color: '#34D399' }} fontSize="inherit" />} severity={this.state.errMessage.type == 'error' ? "error" : 'success'}  >
                            {this.state.errMessage.msg}
                        </Alert>
                    </Collapse>
                    <Box style={{ background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)' }}>
                        <Toolbar>
                            {this.renderHeader()}
                        </Toolbar>
                        <MainContent>
                            {!this.state.isSearch && this.renderScreens()}
                        </MainContent>
                    </Box>
                    <Drawer data-test-id="confirmation-alert" className="confirmationAlert" anchor={'bottom'} open={this.state.showAlert}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                            <Button variant="text" data-test-id="closeBtn" style={{ margin: '20px 3px', padding: '0px' }}
                                onClick={() => this.closeAlertPopUp()}
                            ><Close style={{ fontSize: '2em', fill: "#fff" }} /></Button>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0px 24px 24px' }}>

                            <Typography component="div" style={{ fontSize: '1.1em', fontWeight: 700, margin: '12 0 0', color: '#fff' }}>
                                {`Are you sure you want to ${this.state.currentRowData.status == 'blocked' ? 'Unblock' : 'Block'} the user?`}
                            </Typography>
                            <Button
                                className={"textBtn"}
                                fullWidth
                                variant={"text"}
                            onClick={() => this.setState({ showAlert: false })}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={"conatinedBtn"}
                                fullWidth
                                variant={"contained"}
                                onClick={() => this.performActionOnBlock()}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </Drawer>
                </ThemeProvider>
                {/* {this.state.displayAlert ? <Box style={{ ...styles.successStatusWrapper, position: 'sticky' }} data-test-id="card_success">
                    <Box style={styles.successStatus}>
                        <img src={success_icon} style={{ height: '25px', width: '25px' }} /> {'Search history has been deleted'}
                    </Box>
                </Box> : null} */}
            </HamburgerMenu>
        );
    }
}


const StyledHeader = styled(AppBar)({
    width: '100%',
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    background: 'linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)',
    boxShadow: "none",
    alignItems: "centner",
    padding: "20px 16px 16px 20px",
    color: "#FFF",
    "& .pageTitle": {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    borderBottom: "1px solid #64748B",
});

const MainContent = styled(Box)({
    background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
    minHeight: "80vh",
});

const UpdatedTableContainer = styled(TableContainer)({
    flexGrow: 1,
    overflow: 'hidden',
})

const TopActions = styled(Box)({
    padding: "16px 16px 30px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "12px"
})

const StyledTextField = styled(TextField)({
    background: "#01010199",
    borderRadius: "8px",
    width: "100%",

    "& .MuiOutlinedInput-root": {
        height: "48px",
    },
    "& .MuiOutlinedInput-input": {
        padding: "0",
        color: "#94A3B8",
    },
    "&.MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent"
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0px"
    },
    "& .MuiOutlinedInput-input::placeholder": {
        color: "#94A3B8",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px"
    },
    "& .searchIcon": {
        color: "#94A3B8"
    },
    "& .clearIcon": {
        color: "#94A3B8",
        cursor: "pointer"
    },
    "& MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedStart MuiOutlinedInput-adornedStart": {
        border: "1px solid Green",
        borderImageSource: "linear-gradient(175.74deg, #FFFFFF 3.31%, #999999 96.39%)",
    },

});

const RecentSearch = styled(Box)({
    "& .rsTitle": {
        textTransform: "uppercase",
        paddingLeft: "16px",
        color: "#94A3B8",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginBottom: "16px"
    },
    "& .recentSearch": {
        maxHeight: '800px',
        overflow: 'scroll',
    }
})

const RenderScreens = styled(Box)({
    "& .rsTitle": {
        textTransform: "uppercase",
        paddingLeft: "16px",
        color: "#94A3B8",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        marginBottom: "16px"
    },
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100svh - 125px)',
})

const TableBox = styled(Box)({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: "80%",

    "& .MuiPaper-root": {
        background: "transparent",
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    "& .MuiTableCell-root": {
        border: 'none'
    },
    "& .MuiTableCell-head": {
        color: "#94A3B8",
    },
    "& .MuiTableCell-body": {
        color: "#94A3B8",
    },
})

const StyledPopover = styled(Popover)({
    "& .MuiPopover-paper": {
        maxHeight: "calc(100% - 220px)",
        width: "100%",
    },
    "& .formControl": {
        padding: "12px 16px",
        background: "#484848",
        "& .MuiTextField-root": {
            borderRadius: "24px"
        },
        "& .MuiOutlinedInput-root": {
            height: "40px",
            borderRadius: "24px",
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#94A3B8"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#94A3B8"
            },

        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px"
        },
    },
    "& .regionsList": {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        listStyle: "none",
        marginTop: 0,
        marginBottom: 0,
        background: "#1B1B1BCC",
    },
    "& .regionsListItem": {
        height: "48px",
        padding: "12px 16px",
        background: "#1B1B1BCC",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#FFF",
        boxSizing: "border-box"
    },
    "& .regionsListItem:hover": {
        background: "#010101CC",
        color: "#FFF",
    }
})


const CustomAlert = styled(Alert)({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fdecea',
    color: '#f44336',
    borderLeft: '3px solid #f44336',
})

const RightAlignedTypography = styled(Typography)({
    textAlign: 'right',
    fontSize: '14px',
    cursor: 'pointer',
    color: ' #94A3B8',
    width: '100%'
});
