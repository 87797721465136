import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import {
  backimage_five,
  backimage_four,
  backimage_one,
  backimage_three,
  backimage_two,
} from "./assets";
import { OnboardingScreensData } from "./Types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  maxSteps: number;
  onboardingSteps: OnboardingScreensData[];
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OnboardingguideController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      activeStep: 0,
      maxSteps: configJSON.onboardingData.length,
      onboardingSteps: configJSON.onboardingData,
      isLoading: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
  }

  // Customizabele Area Start
  async componentDidMount() {
    let newArr: any = this.state.onboardingSteps.map((data: any) => {
      if (data.imgPath == "onBoarding_one") {
        data.imgPath = backimage_one;
      } else if (data.imgPath == "onBoarding_two") {
        data.imgPath = backimage_two;
      } else if (data.imgPath == "onBoarding_three") {
        data.imgPath = backimage_three;
      } else if (data.imgPath == "onBoarding_four") {
        data.imgPath = backimage_four;
      } else if (data.imgPath == "onBoarding_five") {
        data.imgPath = backimage_five;
      }
      return data;
    });
    this.setState({ onboardingSteps: newArr });
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: 0 });
  };
  handleSkip = () => {
    this.setState({ activeStep: this.state.maxSteps - 1 });
  };
  handleFinish = () => {
    const onboardingFinished: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    onboardingFinished.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    onboardingFinished.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "HomeDashboard"
    );
    this.send(onboardingFinished);
  };
  exitOnboarding = () => {};
  // Customizable Area End
}
