
import React, { useState, useCallback } from "react";

import {
  styled,
  Divider,
  Box,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { Formik } from "formik";
import {CardDetailType} from './CollectPayment';

const master_card_icon = require("../assets/master_card_icon.png");
const visa_dark_card_icon = require("../assets/visa_dark_icon.png");

export interface CardDataTypes {
  cardNumber: string;
  cardHolderName: string;
  expiryDate: string;
  cvv: string;
}
export interface IHandleAddFormData {
  cardData: CardDataTypes
	formData: string
}
export interface CardDetailFormProps {
  getCardIconSrc: (data: CardDetailType) => string;
  selectedCardDataDetails: any;
  incorrectCardNumber: boolean;
  cardErrorMessage: string;
  handleCardSubmit: (data: IHandleAddFormData) => void;
}

export default function CardDetailForm(props: CardDetailFormProps) {
  const {
    getCardIconSrc,
    selectedCardDataDetails,
    incorrectCardNumber,
    cardErrorMessage,
    handleCardSubmit
  } = props;

  const [isAllFieldFill, setIsAllFieldFill] = useState<boolean>(false)
  const [serverError, setServerError] = useState<boolean>(false)
  const [yearError, setYearError] = useState<boolean>(false)
  
  const [cardData, setCardData] = useState<CardDataTypes>({
    cardNumber: "",
    cardHolderName: "",
    expiryDate: "",
    cvv: ""
  })

  const addButtonEnableDisable = () => {
    if (cardData.cardNumber || cardData.cardHolderName || cardData.expiryDate || cardData.cvv) {
      setIsAllFieldFill(true);
    } else {
      setIsAllFieldFill(false);
    }
    if (cardData.cardNumber && cardData.cardHolderName && cardData.expiryDate && cardData.cvv) {
      setServerError(false);
    } 
  }
  
  const getCardSrc = () => {
		const { cardNumber } = cardData;
		if (cardNumber.startsWith("2") || cardNumber.startsWith("5")) {
		  return master_card_icon;
		} else if (cardNumber.startsWith("4")) {
		  return visa_dark_card_icon;
		} else {
		  return "";
		}
	};

  const handleCardNumber = (e: any) => {
    // added card 19 digit limit  
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 19) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 19);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }
    handleCardData('cardNumber', spacedNumber );
  };

  const handleCardHolderName = (e: any) => {
    const { value } = e.target;
    const newValue = value.replace(/[^A-Za-z\s]/g, '');
    
    if (newValue.length <= 24) {
      handleCardData('cardHolderName', newValue );
    }
  };
  const handleExpiryDate = (e: any) => {
    let input = e.target.value;
    input = input.replace(/\D/g, '');

    if (input.length > 2) {
      input = input.slice(0, 2) + '/' + input.slice(2);
    }

    const parts = input.split('/');
    if (parts.length === 2) {
      parts[1] = parts[1].slice(0, 4);
      input = parts.join('/');
    }
    if(parts[1] && new Date().getFullYear() > parts[1].toString()) {
      setYearError(true)
    } else {setYearError(false)}

    if(parts[1] && parts[1].length === 2 && new Date().getFullYear().toString().slice(2, 4) > parts[1].toString()) {
      setYearError(true)
    } else if(parts[1] && parts[1].length > 2 && new Date().getFullYear() > parts[1].toString()) {
        setYearError(true)
    }
    else {setYearError(false)}

    if (parts?.[0].length <= 2 || parts?.[1].length <= 4) {
      handleCardData('expiryDate', input);
    }
  }

  const handleCvv = (event: any) => {
    const inputVal = event.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 4) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 4);
    }
    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }
    handleCardData('cvv', Number(spacedNumber));
  }

  const handleCardData = useCallback((key, value) => {
    setCardData((prevData: CardDataTypes) => ({
        ...prevData,
        [key]: value
    }));
    addButtonEnableDisable();
  }, [cardData]);

  const handleSubmitForm = (values: any) => {
    if (!values.cardNumber || !values.cardHolderName || !values.expiryDate || !values.cvv) {
			setServerError(true);
			return;
		}
    const [month, year] = cardData.expiryDate.split('/');
    const cardNumber = cardData.cardNumber.split(' ').join('');

    const formData = new URLSearchParams({
			'card[number]': cardNumber,
			'card[exp_month]': month,
			'card[exp_year]': year,
			'card[cvc]': cardData.cvv
		});
    const requestBody = formData.toString();
    handleCardSubmit({cardData, formData: requestBody});
  };
  const handlePastChange = (event: React.ClipboardEvent) => {
    event.preventDefault();
  };

    return (
      // Customizable Area Start
      <Box>
        <div style={{ paddingTop: '28px' }}>
          <Typography variant="body1" style={webStyle.enterCardDetails}>ENTER CARD DETAILS</Typography>
        </div>
        <Box sx={{ padding: '12px 24px' }}>
          <Formik
            initialValues={{ 
              cardNumber: cardData.cardNumber, 
              cardHolderName: cardData.cardHolderName, 
              expiryDate: cardData.expiryDate, 
              cvv: cardData.cvv 
            }}
            onSubmit={handleSubmitForm}
            data-test-id="formik_form"
          >
            {({ setFieldValue, handleSubmit }) => (
              <form data-test-id="submit_form" onSubmit={handleSubmit} noValidate>
                <Grid item xs={12} style={webStyle.inputWrapper}>
                  <InputLabel>
                    Card number
                  </InputLabel>
                  <TextField
                    data-test-id="cardNumber"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          {getCardSrc()?.length ? <IconButton
                            aria-label="toggle visibility"
                            edge="end"
                          >
                            <img className="cardIcon" src={getCardSrc()} />
                          </IconButton>
                           : ''}
                        </InputAdornment>
                    }}
                    placeholder="0000 0000 0000 0000"
                    value={cardData.cardNumber}
                    onChange={(event: any) => {
                      handleCardNumber(event);
                      setFieldValue("cardNumber", cardData.cardNumber);
                    }}
                    onError={() => {
                      <Box sx={webStyle.errorBox} data-test-id="btnInvalidMethod">
                        Invalid expiry year. Please check details to proceed.
                      </Box>
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={webStyle.inputWrapper}>
                  <InputLabel>
                    Cardholder name
                  </InputLabel>
                  <TextField
                    data-test-id="cardHolderName"
                    fullWidth
                    variant="outlined"
                    placeholder="Cardholder name"
                    value={cardData.cardHolderName}
                    onChange={(event: any) => {
                      handleCardHolderName(event);
                      setFieldValue("cardHolderName", cardData.cardHolderName);
                    }}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6} lg={6} xl={6} style={webStyle.inputWrapper}>
                    <InputLabel>
                      Expiry Date
                    </InputLabel>
                    <TextField
                      data-test-id="expiryDate"
                      fullWidth
                      variant="outlined"
                      placeholder="MM/YYYY"
                      value={cardData.expiryDate}
                      onChange={(event: any) => {
                        handleExpiryDate(event);
                        setFieldValue("expiryDate", cardData.expiryDate);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} xl={6} style={webStyle.inputWrapper}>
                    <InputLabel>
                      CVV
                    </InputLabel>
                    <TextField
                      data-test-id="cvv"
                      fullWidth
                      variant="outlined"
                      placeholder="3 - 4 digits"
                      value={cardData.cvv}
                      onChange={(event: any) => {
                        handleCvv(event);
                        setFieldValue("cvv", cardData.cvv);
                      }}
                    />
                  </Grid>
                </Grid>
    						<CustomDivider style={{margin: '20px 0'}} />

                {yearError ?
                  <Box sx={webStyle.errorBox} data-test-id="btnInvalidMethod">
                    Invalid expiry year. Please check details to proceed.
                  </Box> :
                  ""}
                {cardErrorMessage.length ? (
                    <Box sx={webStyle.errorBox} data-test-id="btnInvalidMethod">
                      {cardErrorMessage}, Please re enter details
                    </Box>
                  ): ""}  
                {serverError ?
                  <Box sx={webStyle.errorBox} data-test-id="btnServerError">
                    Details missing. Please fill details to proceed.
                  </Box> : ""
                }
                <button
                  // ref={this.submitRef}
                  data-test-id="btnAddPaymentMethod"
                  type="submit"
                  disabled={!isAllFieldFill || yearError}
                  style={{
                    ...webStyle.addPaymentBtn,
                    color: "#000000",
                    background: !isAllFieldFill && "linear-gradient(174.53deg, rgba(255, 255, 255, 0.4) 4.52%, rgba(145, 145, 145, 0.4) 103.95%)" || "",
                  }}
                >
                  {"Save"}
                </button>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
      // Customizable Area End
    );
}
// Customizable Area Start
const CustomDivider = styled(Divider)({
	backgroundColor: "#64748B",
	height: '1px',
});

const webStyle = {
  bankCardWrapper: {
    background: "linear-gradient(99.09deg, #696969 2.64%, #07000F 100%)",
    height: "100vh",
    overflowY: 'auto',
    fontFamily: "Inter, sans-serif"
  },
  cardTitleTxt: {
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#FFFFFF",
    padding: "8px 24px 14px 24px",
    borderBottom: "1px solid #64748B"
  },
  enterCardDetails: {
    padding: "16px 24px 8px",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    borderBottom: "1px solid #64748B",
    color: "#F8FAFC"
  },
  inputWrapper: {
    marginBottom: "16px",
    '&.borderBottom': {
      borderBottom: "1px solid #E2E8F0"
    }
  },
  addPaymentBtn: {
    background: "linear-gradient(174.53deg, #FFFFFF 4.52%, #919191 103.95%)",
    padding: "16px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    width: "100%",
    margin: "40px 0",
    borderRadius: "8px",
    border: "none",
    color: "#000000",
    '&:disabled': {
      background: "linear-gradient(174.53deg, rgba(255, 255, 255, 0.4) 4.52%, rgba(145, 145, 145, 0.4) 103.95%)"
    }
  },
  errorBox: {
    borderLeft: "4px solid #DC2626",
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    padding: "12px 16px",
    marginTop: "40px",
    width: 'calc(100vw - 80px)',
    margin: 'auto'
  },
  successStatus: {
    maxWidth: "300px",
    padding: "10px",
    display: "flex",
    margin: "0 auto",
    color: "#0F172A",
    fontSize: "16px",
    lineHeight: "24px",
    position: 'fixed',
    bottom: "20px",
    width: 'calc(100vw - 80px)',
    borderRadius: "8px",
    backgroundColor: "#fff",
    '& img': {
      marginRight: "8px",
      width: "24px"
    },
    alignItems: 'center',
    gap: '10px',
  },
  successStatusWrapper: {
    position: "absolute",
    bottom: "50px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  cardErrorBox: {
    borderLeft: "4px solid #DC2626",
    fontSize: "12px",
    lineHeight: "18px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    padding: "15px 16px",
    margin: "15px 0",
    // position: 'fixed',
    // top: '60vh',
    width: '100%',
    boxSizing: "border-box",
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    top: 0,
    zIndex: 9999,
    width: "100%",
  },
  dialogWrapper: {
    borderRadius: "16px"
  },
  dialogContentWrapper: {
    fontSize: "20px",
    color: "#000"
  },
  deleteActionWrapper: {
    justifyContent: "space-between",
    padding: 0,
  },
  yesButton: {
    margin: 0,
    padding: "12px",
    width: "50%"
  },
  noButton: {
    margin: 0,
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    width: "50%",
    padding: "12px",
    borderRadius: "unset"
  }
};

// Customizable Area End


