import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import { imgPasswordVisible, imgPasswordInVisible } from "./assets";
import {  lowerCase, capitalCase, numberCase, specialChar } from "../../utilities/src/RegExRequired";
import {GenericApiCall} from '../../utilities/src/GenericApiCall'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  handlePageState?: (data: string) => void;
  handleMessage?: (message: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  isEmailValid:boolean;
  isMailRequired:boolean;
  showPasswordValidation:boolean;
  newPassword:string;
  confirmPassword:string;
  showConfirmPass: boolean;
  showNewPass: boolean;
  passwordErrors:any;
  errorMsg: string;
  disableRecoveryLink: boolean,
  inValidPassword: boolean,
  passwordUpdated:boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendRecoveryMailApiId:string ='';
  updatePasswordApiCallId:string='';
  getUserDetailsApiCallId:string='';
  //Properties from config
  isChangePassword: boolean = true;
  labelTextIsAccountRecovery: string = configJSON.labelTextIsAccountRecovery;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      isEmailValid:false,
      isMailRequired:false,
      showPasswordValidation:false,
      newPassword:'',
      confirmPassword:'',
      showNewPass: true,
      showConfirmPass: true,
      passwordErrors:[
        { error: 'capital', msg: 'At least one capital letter'},
        { error: 'lower', msg: 'At least one lowercase letter'},
        { error: 'number', msg: 'At least one number'},
        { error: 'length', msg: 'Minimum character length is 8 characters'}
      ],
      errorMsg:'',
      disableRecoveryLink: false,
      inValidPassword: false,
      passwordUpdated:false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    console.log('window.location',window.location.pathname)
    if(window.location.pathname.includes('NewPassword')){

      this.getUserEmail()
    }
    // Customizable Area End
  }
// Customizable Area Start
  getUserEmail=()=>{
    let token = window.location.href.split('=')[1];
    let requestMesg = GenericApiCall(configJSON.forgotPasswordAPiContentType, configJSON.httpGetMethod, configJSON.userDetailsApiEndPoint,token)
    this.getUserDetailsApiCallId = requestMesg.messageId;
    runEngine.sendMessage(requestMesg.id, requestMesg)
  }
// Customizable Area End
  async receive(from: string, message: Message) { 
    // Customizable Area Start
    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      let mail = message.getData('recoveryEmail')
      this.setState({emailValue:mail,disableRecoveryLink:false})
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    console.log(errorReponse);
    
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    

    if (apiRequestCallId != null && responseJson) {
      if(apiRequestCallId == this.sendRecoveryMailApiId){
        if(responseJson.errors){
          this.setState({errorMsg:responseJson.errors[0].message})
        }
        else if(responseJson.message){
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage))
          raiseMessage.addData("recoveryEmail",this.state.emailValue)
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          msg.addData(getName(MessageEnum.NavigationRaiseMessage),raiseMessage)
          msg.addData(getName(MessageEnum.NavigationTargetMessage), "RecoveryMailSuccess");
          this.send(msg);
       
        }
      }
      else if(apiRequestCallId == this.updatePasswordApiCallId){
        this.handleUpdatePasswordResponse(responseJson)
      }
      else if(apiRequestCallId == this.getUserDetailsApiCallId){
        console.log('resposnse',responseJson)
        this.setState({emailValue:responseJson.data.attributes.email})
      }
    }
  // Customizable Area End
  }
// Customizable Area Start
handleUpdatePasswordResponse=(responseJson:any)=>{
  if(responseJson.errors){
    this.setState({errorMsg:responseJson.errors[0].message})
  }
  else{
    this.setState({passwordUpdated:true})
  }
}
  startForgotPassword(accountType: String) {
    this.setState({
      accountStatus: accountType === "sms" ? "EnterPhone" : "EnterEmail",
    });
  }

  naviagteToLogIn=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    this.send(msg);
  }
  
  setEmailValue=(email_address:string)=>{
    this.setState({emailValue:email_address})
  }

  setNewPassword=(password:string)=>{
    
    this.setState({newPassword:password})
    this.state.passwordErrors.map((error:any)=>{
      error.isValid = this.setPasswordValidationErrors(error.error,password);
    })
    this.setState({inValidPassword:this.setPasswordValidationErrors('default',password)})
  }
  setPasswordValidationErrors=(typeOfError:string,password:string)=>{
    switch (typeOfError) {
      case 'lower':
        return lowerCase.test(password)
        case 'capital':
          return capitalCase.test(password)
        case 'number':
          return numberCase.test(password)
        case 'length':
          return password.length>7
      default:
        if(numberCase.test(password) && lowerCase.test(password) && capitalCase.test(password) && password.length>7){
          return false
        }
        else{
        return true
        }
    }
  }
  setConfirmPassword=(password:string)=>{
    this.setState({confirmPassword:password})
  }
  sendPasswordChangeRequest=(event:any)=>{
    let token = window.location.href.split('=')[1];
    event.preventDefault()
    if(this.state.newPassword != this.state.confirmPassword ){
      this.setState({errorMsg:"New password and confirm password do not match"})
    }
    else if(!this.state.inValidPassword && !specialChar.test(this.state.newPassword)){
      this.setState({errorMsg:`Following chars not alloewd: ~+-=<>,?{}[]:; “ ”,' '`})
      return false
    }
    else if(this.state.newPassword && this.state.confirmPassword && !this.state.inValidPassword){
      this.setState({errorMsg:""})
      let httpBody ={
        "data":
    {
        "new_password": this.state.newPassword,
        "token": token
    },
     "type": "email_account"
      }
      console.log("Password  match")
      let requestMesg = GenericApiCall(configJSON.forgotPasswordAPiContentType,configJSON.httpPostMethod,configJSON.newPasswordApiEndPoint,'',JSON.stringify(httpBody))
      this.updatePasswordApiCallId = requestMesg.messageId;
      runEngine.sendMessage(requestMesg.id,requestMesg)
      return true
    }
  }
  handleErrorMsg = () => {
    this.setState({errorMsg:'Please enter your email to reset your password'});
  }
  sendRecoveryLink=(event:any)=>{
    event.preventDefault()
    if(!this.state.emailValue){
      this.setState({isMailRequired:true})
      this.handleErrorMsg()
      return false
    }
    else{
      this.setState({isMailRequired:false})
      let httpBody = {
        'email': this.state.emailValue,
        'host' : window.location.origin
      }
      let requestMesg = GenericApiCall(configJSON.forgotPasswordAPiContentType,configJSON.httpPostMethod,configJSON.recoveryEmailApiEndPoint,'',JSON.stringify(httpBody))
      this.sendRecoveryMailApiId = requestMesg.messageId;
      runEngine.sendMessage(requestMesg.id,requestMesg)
      return true
    }
  }
  reSendLink=(event:any)=>{
    this.sendRecoveryLink(event)
  }
  setShowPassword=(showKey:string)=>{
    console.log('show key------',showKey)
    if(showKey == 'new'){
    this.setState({showNewPass:!this.state.showNewPass})
    }
    else if(showKey == 'confirm'){
      this.setState({showConfirmPass:!this.state.showConfirmPass})
    }
  }
  validatePassword=(action:string)=>{
    if (action == 'focus') {
      this.setState({ showPasswordValidation: true })
    } else if (action == 'blur' && this.state.newPassword.length == 0) {
      this.setState({ showPasswordValidation: false })
    } else {
      this.setState({ showPasswordValidation: true })
    }
  }
  closeSuccesAlert=()=>{
    this.setState({passwordUpdated:false})
  }
  proceedFurther=()=>{
    this.naviagteToLogIn()
  }
  // Customizable Area End
}
